import { lightTheme } from '@mms/mms-ui-library';
import { styled } from '@mui/material';

export const LogoWrapper = styled('div')(() => ({
	maxHeight: 22,
	display: 'flex',
	alignItems: 'center',
}));

export const Title = styled('h6')(() => ({
	color: lightTheme.palette['primary-1'],
	fontSize: 18,
	fontWeight: 600,
	lineHeight: 'normal',
	display: 'inline-flex',
}));

export const SubTitle = styled('p')(() => ({
	color: lightTheme.palette['primary-1'],
	fontSize: 18,
	fontWeight: 600,
	lineHeight: 'normal',
	display: 'inline-flex',
}));
