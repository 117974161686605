export enum NotificationType {
	INFO = 'info',
	SUCCESS = 'success',
	ERROR = 'error',
}

export const SESSION_EXPIRED = {
	title: 'Session expired',
	description:
		'Your session has timed out. Please, log in again to continue working',
};
export const PROFILE_UPDATING = {
	title: 'Profile updating',
	description: 'Your changes have been successfully saved',
};
export const CLIENT_ADDED = 'Client is added';
export const CLIENT_DELETED = 'Client is successfully deleted';
export const CLIENT_UPDATED = 'Client is successfully updated';
export const PROJECT_ADDED = 'Project is added';
export const PROJECT_UPDATED = 'Project is successfully updated';
export const USER_ADDED = 'User successfully added';
export const USER_UPDATED = 'User is successfully updated';
export const EMAIL_DUPLICATE = 'Email duplicate';
export const CERTIFICATE_GENERATED_SUCCESSFULLY =
	'Certificate number successfully generated';
export const CERTIFICATE_GENERATED_ERROR =
	'Error generating certificate number';
export const CLIENT_DELETE_ERROR_IN_PROGRESS =
	'Client was not deleted due to having project(s) in progress';
export const NOTIFICATION_MESSAGE = {
	PROJECT_UPDATED: 'Project updated',
	CANNOT_DEACTIVATE_PROJECT:
		'Project can not be deactivated. To deactivate project you should remove users from project',
};
export const BOOKING_CANCELED = 'Booking has been canceled';
export const BOOKING_CREATED = 'Desk has been successfully booked';
export const DESC_UNBLOCKED = 'Desk has been successfully unblocked';
export const BOOK_ANOTHER_DATE =
	'You can cancel the booking or choose another date';
export const YOU_SURE = 'Are you sure?';
export const SURE_TO_CANCEL_BOOKING =
	'Are you sure you want to cancel your booking?';
export const SURE_TO_CANCEL = 'Are you sure you want to cancel';
export const SURE_TO_UNBLOCK = 'Are your sure you want to unblock the desk?';
export const SURE_TO_BLOCK = 'Are you sure you want to block the desk?';
export const MAXIMUM_ALLOWED_FILE_SIZE = {
	title: 'Exceeding file size',
	message: 'File max size should not exceed 0,5 MB',
};
export const RIGHT_EXTENSIONS = {
	title: 'Invalid file format',
	message: 'You can upload files only in JPG, JPEG, PNG formats',
};
export const EMPTY_FILE = 'The file is empty. You can’t upload an empty file';
export const MORE_THAN_ZERO = 'File size should be greater than 0 MB';
export const ENTER_VALID_RETURN_DATE = 'Select a valid Return date';
export const INVALID_DATE_RANGE = 'The date range is invalid';
export const SELECT_DATE_INPUT = 'Select the date';
export const USER_DELETED = 'User successfully deleted';
export const ERROR_MESSAGE_UPDATE_PROJECT =
	'You can’t edit the Project in saved record. To edit Project you should clear tracked hours manually at first';
export const CANT_ASSIGN_ROLE = 'You can assign users with employee role only';
export const EQUIPMENT_CREATED =
	'Equipment details have been successfully added';
export const EQUIPMENT_UPDATED =
	'Equipment details have been successfully edited';
export const ALREADY_BOOKED = 'You have already had a booking for';
export const COPY_TO_CLIPBOARD = 'Signature has been successfully copied';
export const HAS_BEEN_CLEARED = 'Signature form has been successfully cleared';
export const HAS_BEEN_DOWNLOADED = 'Signature has been successfully downloaded';
export const HAS_BEEN_GENERATED = 'Signature has been successfully generated';
export const AUTHORIZATION_ERROR_MESSAGE = {
	title: 'Error during authorization',
	message: 'Something went wrong',
};
export const CANNOT_DEACTIVATE_USER = `The user can't be deactivated. Please check if the employee has returned all the issued equipment and being not an approver, the employee’s assignment to project(s) is removed and the employee has no active workplace bookings at first`;
export const USER_NOT_FOUND = `User not found`;
export const TASK_NAME_ALREADY_EXISTS = {
	title: 'A task with the same name already exists',
	body: 'Please change the task name within the timesheet period',
};
export const SAME_SERIAL_NUMBER_ALREADY_EXISTS =
	'An equipment with the same serial number already exists';
export const PLEASE_CHANGE_SERIAL_NUMBER = 'Please change the serial number';
export const DESK_IS_ALREADY_BOOKED =
	'Selected desk has been already booked. You can choose another available desk';
export const ISSUE_DATE_SHOULD_NOT_BE_LESS_LAST_RETURN_DATE =
	'Issue date shouldn’t be less than latest Return date';

export const SKILL_CREATED = 'has been successfully added';
