export interface KnowledgeType {
	id: string | number;
	name: string;
	description: string;
}
interface KnowledgeJsStackType {
	id: number;
	name: string;
	description: string;
}

export interface DepartmentKnowledgeType {
	id: string;
	name: string;
	description: string;
}

export enum LocationType {
	Minsk = 'Minsk',
	Warsaw = 'Warsaw',
	Vitebsk = 'Vitebsk',
	Tbilisi = 'Tbilisi',
}

export interface OfficeKnowledgeType {
	id: string;
	name: string;
	description: string | null;
	location: LocationType;
}

export interface LocationKnowledgeType {
	id: LocationType;
	name: LocationType;
	description: LocationType;
	location: null;
}

export enum PermissionTypeId {
	TrackBillableWorkingHours = 'TrackBillableWorkingHours',
}
export interface PermissionType {
	id: PermissionTypeId;
	name: PermissionTypeId;
	description: PermissionTypeId;
	location: null;
}

export enum ResourceTypeId {
	Project = 'Project',
}

export interface ResourceType {
	id: ResourceTypeId;
	name: ResourceTypeId;
	description: ResourceTypeId;
	location: null;
}

export interface KnowledgeBase {
	PermissionType: Array<PermissionType>;
	ResourceType: Array<ResourceType>;
	Offices: Array<OfficeKnowledgeType>;
	DepartmentType: DepartmentKnowledgeType[];
	LocationType: Array<LocationKnowledgeType>;
	RoleType: KnowledgeType[];
	JsStackType: KnowledgeJsStackType[];
	EquipmentType: KnowledgeType[];
	EquipmentLocationType: KnowledgeType[];
	EquipmentAvailabilityType: KnowledgeType[];
	MoneyCurrencyType: KnowledgeType[];
}
