import { Autoselect } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import moment from 'moment';
import { useMemo, memo } from 'react';

import { BOOKING_MOBILE_SCREEN_WIDTH } from '../../constants';

import { ProtectedView } from '@/components/ProtectedView';
import { Roles, managerRoles } from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useAuth } from '@/context/index';
import { useScreenSizeDown } from '@/hooks/index';
import { BookingControls, BookingTime } from '@/pages/Booking/components';
import { useUsersLookup } from '@/queries/useUsersLookup';

import { StyledScrollbar, Wrapper } from './styled';
import { getEmployeeOptions } from './utils';

const AutoselectMemoized = memo(Autoselect, isEqual);

export function BookingManagement() {
	const { firstDay, lastDay, employee, setEmployee, initialEmployee } =
		useBookingContext();

	const isMobileView = useScreenSizeDown(BOOKING_MOBILE_SCREEN_WIDTH);

	const { role } = useAuth();
	const theme = useTheme();
	const { data, isFetching } = useUsersLookup({
		search: '',
		queryOptions: { enabled: role !== Roles.SE },
		isActive: true,
	});
	const employeeOptions = useMemo(
		() => getEmployeeOptions(data, isFetching),
		[data, isFetching]
	);
	const isSameDate = moment(firstDay).isSame(lastDay, 'day');

	const label = employee
		? employee.positionDescription
		: initialEmployee.positionDescription;

	return (
		<Wrapper>
			<StyledScrollbar
				isManagerRoles={managerRoles.includes(role)}
				customScrollbarWidth={3}
				scroll="vertical"
				maxHeight="100%"
				maxWidth="100%"
			>
				<BookingTime>
					<ProtectedView roles={managerRoles}>
						<AutoselectMemoized
							label={label}
							value={employee && employee.name ? employee : initialEmployee}
							valueField="name"
							options={employeeOptions}
							onChange={setEmployee as any}
							valueToClean={initialEmployee}
							labelAndErrorBgColor={
								isMobileView
									? theme.palette['primary-2']
									: theme.palette['primary-1']
							}
							getKey={(option) => (option as { id: number }).id}
							checkIfOptionEqualToValue={(option, autoselectValue) =>
								(option as { id: number }).id ===
								(autoselectValue as { id: number }).id
							}
						/>
					</ProtectedView>
				</BookingTime>

				<BookingControls isControlDisabled={!isSameDate} />
			</StyledScrollbar>
		</Wrapper>
	);
}
