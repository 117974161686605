import { DeskDirectionRotateDegree } from '@/constants/booking';

export const getRotatedSeatStyles = (degree: DeskDirectionRotateDegree) => {
	if (degree === DeskDirectionRotateDegree.Left) {
		return {
			top: '50%',
			right: '-17px',
			transform: `translate(-50%, -50%)`,
		};
	}

	if (degree === DeskDirectionRotateDegree.Right) {
		return {
			top: '50%',
			left: '-7px',
			transform: `translate(-50%, -50%) rotate(${degree}deg)`,
		};
	}

	if (degree === DeskDirectionRotateDegree.Top) {
		return {
			bottom: '-7px',
			left: '50%',
			transform: `translate(-50%, 50%) rotate(${degree}deg)`,
		};
	}

	if (degree === DeskDirectionRotateDegree.Bottom) {
		return {
			top: '-7px',
			left: '50%',
			transform: `translate(-50%, -50%) rotate(${degree}deg)`,
		};
	}
};
