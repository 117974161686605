import { CalendarUI } from '@mms/mms-ui-library';
import { styled } from '@mui/material';

import { BOOKING_SMALL_MOBILE_SCREEN_WIDTH } from '../../constants';

export const BookingTimeWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: theme.spaces.s,
	width: 350,

	[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
		width: '100%',
	},
}));

export const DatePickersWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: theme.spaces.s,
	width: 350,
	marginBottom: theme.spaces.m,

	[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
		width: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
	},

	'[data-testid="data-picker-view"] > div': {
		width: '100%',
		minWidth: '100%',
		maxWidth: '100%',
	},
}));

export const StyledCalendarUI = styled(CalendarUI)(({ theme }) => ({
	'.MuiPickersArrowSwitcher-button': {
		display: 'none',
	},

	'.MuiPickersDay-today:not(.Mui-selected)': {
		color: `${theme.palette['base-6']} !important`,
		border: `1px solid ${theme.palette['accent-6']} !important`,
		background: `${theme.palette['accent-1']} !important`,
		...theme.typography.body['m-400'],
	},

	'#rangeDays': {
		backgroundColor: `${theme.palette['accent-5']} !important`,
		borderRadius: '50%',
		border: 'unset !important',
		opacity: `1 !important`,
		color: `${theme.palette['primary-1']} !important`,

		'&::before': {
			height: 16,
			right: -2,
		},

		'&::after': {
			height: 16,
			right: -2,
		},
	},

	'& .MuiDayCalendar-slideTransition': {
		boxSizing: 'content-box',
		overflow: 'visible',
	},

	'& .MuiDayCalendar-monthContainer': {
		overflow: 'visible',
	},

	'.MuiBadge-root': {
		boxSizing: 'unset',
		display: 'flex',
		justifyContent: 'center',
		width: 32,
		padding: '0 2px',

		'& .MuiPickersDay-root': {
			margin: 0,
		},
	},

	'.MuiBadge-badge': {
		background: theme.palette['accent-6'],
		border: `1px solid ${theme.palette['primary-1']}`,
		right: '21%',
	},
}));
