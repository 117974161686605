import { Scrollbar, Typography, getScrollbarStyles } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import {
	BOOKING_TABS_CHANGE_SCREEN_WIDTH,
	BOOKING_MOBILE_SCREEN_WIDTH,
	BOOKING_DESK_SEAT_WIDTH,
	BOOKING_DESKTOP_CHANGE_SCREEN_WIDTH,
	BOOKING_LAPTOP_CHANGE_SCREEN_WIDTH,
} from '../../constants';

export const BookingWorkplaceWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	display: 'grid',

	gridTemplateAreas: `
	'title'
	'workplace'
	'legend'
	`,
	gridTemplateColumns: '1fr',
	gridTemplateRows: '36px 1fr 24px',
	rowGap: theme.spaces.m,

	[theme.breakpoints.down(BOOKING_TABS_CHANGE_SCREEN_WIDTH)]: {
		rowGap: theme.spaces.l,
		justifySelf: 'center',
	},

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		gridTemplateAreas: `
		'title legend'
		'workplace workplace'
		`,
		gridTemplateColumns: '1fr auto ',
		gridTemplateRows: '36px 1fr',

		justifySelf: 'start',
		alignItems: 'center',
	},
}));

export const WorkplaceWrapper = styled(Scrollbar)(({ theme }) => ({
	gridArea: 'workplace',
	width: '100%',
	height: '100%',

	paddingLeft: BOOKING_DESK_SEAT_WIDTH,
	paddingRight: BOOKING_DESK_SEAT_WIDTH,

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		...getScrollbarStyles(theme, 3, 'both'),
	},
}));

export const WorkplaceLoaderWrapper = styled('div')(() => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const MinskWorkplace = styled('div')(({ theme }) => ({
	display: 'grid',
	alignContent: 'center',
	gridTemplateAreas: `
		 "desk-1 desk-2 . desk-3 desk-4 . desk-5 desk-6 . desk-7"
		 "desk-8 desk-9 . desk-10 desk-11 . desk-12 desk-13 . ." 
		 ". . . . . . . . . ." 
		 ". . . . . . . . desk-22 desk-27" 
		 ". . desk-14 desk-18 . wall . . desk-23 desk-28" 
		 ". . desk-15 desk-19 . wall . . desk-24 desk-29" 
		 ". . desk-16 desk-20 . wall . . desk-25 desk-30" 
		 ". . desk-17 desk-21 . wall . . desk-26 desk-31"`,
	gridTemplateRows: 'repeat(2, 111px) 56px repeat(5, 111px)',
	gridTemplateColumns: 'repeat(10, 76px)',
	gridGap: 3,
	position: 'relative',

	width: '100%',
	height: '100%',

	[theme.breakpoints.down(BOOKING_DESKTOP_CHANGE_SCREEN_WIDTH)]: {
		gridTemplateRows: 'repeat(2, 83px) 40px repeat(5, 83px)',
		gridTemplateColumns: 'repeat(10, 57px)',
	},
}));

// eslint-disable-next-line import/no-unused-modules
export const WarsawWorkplace = styled('div')(({ theme }) => ({
	display: 'grid',
	alignContent: 'center',
	gridTemplateAreas: `
		 ". desk-9 desk-9 . . desk-5 desk-5 . . ."
		 ". . . . . . . . desk-1 desk-1"
		 ". . . . . . . . desk-2 desk-2"
		 "desk-8 desk-7 . . desk-6 desk-4 . . . ."
		 "desk-8 desk-7 . . desk-6 desk-4 . . desk-3 desk-3"`,
	gridTemplateRows: 'repeat(3, 96px) 43px 96px',
	gridTemplateColumns: '96px 96px 43px 109px 96px 96px 43px 158px 96px 43px',
	gridGap: 3,
	position: 'relative',
	width: '100%',
	height: '100%',

	[theme.breakpoints.down(BOOKING_LAPTOP_CHANGE_SCREEN_WIDTH)]: {
		gridTemplateRows: 'repeat(3, 66px) 28px 66px',
		gridTemplateColumns: '66px 66px 28px 72px 66px 66px 28px 105px 66px 28px',
	},
}));

export const Title = styled(Typography)(() => ({
	gridArea: 'title',
}));

export const Wall = styled('div')(({ theme }) => ({
	gridArea: 'wall',
	width: 33,
	height: 369,
	backgroundColor: theme.palette.bookingWorkplace.available,
	borderRadius: 3,
	justifySelf: 'end',
	alignSelf: 'center',
	transform: 'translateY(-18%)',

	[theme.breakpoints.down(BOOKING_DESKTOP_CHANGE_SCREEN_WIDTH)]: {
		width: 25,
		height: 278,
	},
}));

export const OfficeWarningContainer = styled('div')(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const OfficeWarningMessageContainer = styled(OfficeWarningContainer)(
	({ theme }) => ({
		gap: theme.spaces.m,
		flexDirection: 'column',
	})
);

export const OfficeWarningTypograpphy = styled(Typography)(() => ({
	whiteSpace: 'normal',
	textAlign: 'center',
}));
