import { styled } from '@mui/material/styles';

import {
	BOOKING_BLOCK_HEADER_HEIGHT,
	BOOKING_MOBILE_SCREEN_WIDTH,
} from '../../constants';

export const StyledList = styled('div')<{ isEmploye?: boolean }>(
	({ theme }) => ({
		width: '100%',
		boxSizing: 'border-box',
		borderLeft: `1px solid ${theme.palette['primary-4']}`,
		height: `calc(100% - ${BOOKING_BLOCK_HEADER_HEIGHT}px)`,

		overflow: 'hidden',

		[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
			height: 'auto',
			overflow: 'unset',
		},
	})
);

export const LoaderWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: theme.spaces.m,

	'& > svg': {
		width: 40,
		height: 40,
	},
}));
