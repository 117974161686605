import { Block, PopUp, Warning } from '@mms/mms-ui-library';
import { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { BOOKING_TABS_CHANGE_SCREEN_WIDTH } from '../../constants';
import { useWorkplaceConfiguration } from '../BookingWorkplace/hooks/useWorkplaceConfiguration';
import { PopUpBody } from '../PopUpBody';

import { ProtectedView } from '@/components/ProtectedView';
import {
	ALREADY_BOOKED,
	BOOK_ANOTHER_DATE,
	REPEAT_WEEKS_MAX_VALUE,
	REPEAT_WEEKS_MIN_VALUE,
	BookingQueriesKeys,
	Directions,
	RequestTypes,
	Roles,
	SURE_TO_BLOCK,
	BLOCK_DIALOG_HEADER,
	isEmployee,
	chiefRoles,
	HttpStatuses,
	NotificationType,
	SOMETHING_WENT_WRONG,
	DESK_IS_ALREADY_BOOKED,
} from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { useAuth, useToast } from '@/context/index';
import {
	getTextFromMultipleDate,
	getDeskBlockDialogSubtext,
	getTooltipTitle,
} from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';
import { useScreenSizeDown } from '@/hooks/index';
import { useCheckCollisions, useCreateBooking } from '@/queries/booking';

import { BookButton } from './BookButton';
import { Repetitive } from './Repetitive';
import { ButtonsWrapper, Wrapper } from './styles';
import type { BookingControlsProps } from './types';

export function BookingControls({ isControlDisabled }: BookingControlsProps) {
	const { CEO, CTO } = Roles;
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [isChecked, setIsChecked] = useState(false);
	const [warning, setWarning] = useState<null | string>(null);
	const [weeksCount, setWeeksCount] = useState(0);
	const { role, id } = useAuth();
	const toast = useToast();

	const hidePopupCloseButton = useScreenSizeDown(
		BOOKING_TABS_CHANGE_SCREEN_WIDTH
	);

	const {
		selected,
		setSelected,
		selectedWorkplace,
		firstDay,
		lastDay,
		initialEmployee,
		employee,
		office,
		init,
		workplaces,
	} = useBookingContext();

	const userHasBookedWorkplace = useMemo(
		() => workplaces.some((workplace) => workplace.userId === id),
		[workplaces, id]
	);

	const weeksInterval = isChecked ? weeksCount : undefined;

	const selectedDate = getTextFromMultipleDate([firstDay] as Moment[]);

	const queryClient = useQueryClient();

	useEffect(() => {
		setIsChecked(false);
	}, [office]);

	useEffect(() => {
		setWeeksCount(isChecked ? 1 : 0);
	}, [isChecked]);

	useEffect(() => {
		setIsChecked(false);
	}, [firstDay]);

	const handleChange = useCallback(() => {
		setWeeksCount(isChecked ? 1 : 0);
		setIsChecked(!isChecked);
	}, [isChecked]);

	const handleWeeksChange = useCallback(
		(direction: string) => () => {
			const isUp = direction === Directions.Up;
			const newValue = isUp ? weeksCount + 1 : weeksCount - 1;

			if (
				(isUp && newValue <= REPEAT_WEEKS_MAX_VALUE) ||
				(!isUp && newValue >= REPEAT_WEEKS_MIN_VALUE)
			) {
				setWeeksCount(newValue);
			}
		},
		[weeksCount]
	);

	const { deskTitleAbriviation } = useWorkplaceConfiguration({ office });

	const { data, isFetching } = useCheckCollisions({
		workplaceNumber: selected,
		fromDate: firstDay,
		toDate: lastDay,
		weeksInterval: weeksCount,
		officeId: office.id,
		deskTitleAbriviation,
		userId: employee?.id ?? initialEmployee.id,
	});

	const isButtonsDisabled = selected === 0 || !firstDay || !lastDay;
	const isBlockDisabled =
		isButtonsDisabled || Boolean(selectedWorkplace?.isBlocked);

	const [isBookButtonDisabled, setIsBookButtonDisabled] =
		useState(isButtonsDisabled);
	const [isBlockButtonDisabled, setIsBlockButtonDisabled] =
		useState(isBlockDisabled);

	useEffect(() => {
		if (isFetching) {
			return;
		}
		setIsBookButtonDisabled((isButtonsDisabled || data) ?? false);
		setIsBlockButtonDisabled(isBlockDisabled);
	}, [data, isButtonsDisabled, isFetching, isBlockDisabled]);

	const handleClose = () => setWarning(null);

	const { mutate } = useCreateBooking({
		onSuccess: () => {
			queryClient.invalidateQueries(BookingQueriesKeys.bookings);
			queryClient.refetchQueries(BookingQueriesKeys.reservations);
		},
		onError: (error) => {
			if (error.response?.status === HttpStatuses.ConflictRequest) {
				if (userHasBookedWorkplace) {
					setWarning(`${ALREADY_BOOKED} ${selectedDate}. ${BOOK_ANOTHER_DATE}`);
				} else {
					setWarning(DESK_IS_ALREADY_BOOKED);
					queryClient.invalidateQueries(BookingQueriesKeys.bookings);
					queryClient.refetchQueries(BookingQueriesKeys.reservations);
					init();
				}
			} else {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);
			}
		},
	});

	const handleBookClick = useCallback(() => {
		if (selected === 0) {
			return;
		}

		mutate({
			workplaceNumber: selected,
			weeksInterval,
			fromDate: firstDay,
			toDate: lastDay,
			requestType: RequestTypes.CreateSelf,
			userId:
				id === employee?.id || isEmployee(role) ? undefined : employee?.id,
			officeId: office.id,
		});
		setIsChecked(false);
		setSelected(0);
	}, [selected, weeksCount, firstDay, lastDay, id, employee?.id, isChecked]);

	const handleBlock = useCallback(() => {
		if (selected === 0) {
			return;
		}
		mutate({
			workplaceNumber: selected,
			fromDate: firstDay,
			toDate: lastDay,
			requestType: RequestTypes.CreateBlock,
			userId: employee?.id,
			officeId: office.id,
		});

		setIsDialogOpen(false);
	}, [selected, firstDay, lastDay, employee?.id, id]);

	const initiateDeskBlock = useCallback(() => {
		if (isButtonsDisabled) {
			return;
		}
		setIsDialogOpen(true);
	}, [isButtonsDisabled]);

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	return (
		<>
			<Wrapper>
				<Repetitive
					isChecked={isChecked}
					isControlDisabled={isControlDisabled}
					weeksCount={weeksCount}
					handleWeeksChange={handleWeeksChange}
					handleChange={handleChange}
				/>
				<ButtonsWrapper isChiefRole={chiefRoles.includes(role)}>
					<BookButton
						disabled={isBookButtonDisabled}
						tooltipTitle={getTooltipTitle(lastDay as Moment, selected) || ''}
						onClick={handleBookClick}
						color="accent"
					>
						Book a desk
					</BookButton>
					<ProtectedView roles={[CEO, CTO]}>
						<BookButton
							disabled={isBlockButtonDisabled}
							color="primary"
							tooltipTitle={getTooltipTitle(lastDay as Moment, selected) || ''}
							onClick={initiateDeskBlock}
						>
							Block a desk
						</BookButton>
					</ProtectedView>
				</ButtonsWrapper>
			</Wrapper>
			{warning && (
				<PopUp
					title="WARNING"
					headerIcon={<Warning />}
					onClose={handleClose}
					type="approve"
					headerType="warning"
					showCloseButton={!hidePopupCloseButton}
				>
					<PopUpBody
						deskTitleAbriviation={deskTitleAbriviation}
						text={warning}
					/>
				</PopUp>
			)}
			{!isButtonsDisabled && isDialogOpen && (
				<PopUp
					title={BLOCK_DIALOG_HEADER}
					headerIcon={<Block />}
					onClose={handleCloseDialog}
					type="approve"
					showCloseButton={false}
					controls={{
						negativeControl: { onClick: handleCloseDialog },
						positiveControl: { onClick: handleBlock },
					}}
				>
					<PopUpBody
						deskTitleAbriviation={deskTitleAbriviation}
						text={SURE_TO_BLOCK}
						subtext={getDeskBlockDialogSubtext(
							firstDay,
							selected,
							deskTitleAbriviation
						)}
					/>
				</PopUp>
			)}
		</>
	);
}
