import {
	CrossCircle,
	Loader,
	PopUp,
	Scrollbar,
	Unblock,
} from '@mms/mms-ui-library';
import moment from 'moment';
import { useEffect, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useWorkplaceConfiguration } from '../BookingWorkplace/hooks/useWorkplaceConfiguration';
import { PopUpBody } from '../PopUpBody';

import { BookingQueriesKeys, isEmployee, Roles } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { useBookingContext } from '@/context/Booking';
import { useToast } from '@/context/Toast';
import type { HighlightedDays } from '@/context/types';
import {
	getDeleteDialogHeader,
	getDeleteDialogSubtext,
	getDeleteDialogText,
	getDialogTextComponent,
} from '@/helpers/index';
import { useGetBookings, useDeleteBooking } from '@/queries/booking/index';

import { Item } from './Item';
import { LoaderWrapper, StyledList } from './styles';
import type { BookingItem } from './types';
import { getDateRange, getRepetitiveWeekDays } from './utils';

export function BookingList() {
	const { id, role } = useAuth();

	const toast = useToast();
	const queryClient = useQueryClient();
	const { setHighlightedDays, office } = useBookingContext();
	const [itemToDelete, setItemToDelete] = useState<BookingItem | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const { data, dataUpdatedAt, refetch, isSuccess, isLoading, isFetching } =
		useGetBookings({
			userId: id,
			includeEmployee: role !== Roles.SA,
			officeId: office.id,
		});

	const { mutate } = useDeleteBooking(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(BookingQueriesKeys.bookings);
				queryClient.refetchQueries(BookingQueriesKeys.reservations);
				refetch();
			},
		},
		toast
	);

	useEffect(() => {
		if (data) {
			const days = data.reduce(
				(acc: HighlightedDays, item) => {
					const { dateList, isBlock, userId, weeksInInterval } = item;
					const momentDateList = dateList.map((date) => moment(date));

					if (userId === id) {
						if (weeksInInterval > 0) {
							const repetitiveWeekDays = getRepetitiveWeekDays(
								momentDateList,
								weeksInInterval
							);
							acc.yourList?.push(...repetitiveWeekDays);

							return acc;
						}

						if (momentDateList.length === 1) {
							acc.yourList?.push(...momentDateList);

							return acc;
						}

						const [rangeStart, rangeEnd] = momentDateList;

						if (isBlock) {
							acc.blockStartDate = rangeStart;
						} else {
							const dateRange = getDateRange(rangeStart, rangeEnd);

							acc.yourList?.push(...dateRange);
						}

						return acc;
					}

					acc.bookedList?.push(...momentDateList);

					return acc;
				},
				{
					bookedList: [],
					blockStartDate: null,
					yourList: [],
				}
			);

			setHighlightedDays(days);
		}
	}, [dataUpdatedAt]);

	const { deskTitleAbriviation } = useWorkplaceConfiguration({ office });

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
		setItemToDelete(null);
	}, []);

	const initiateBookDelete = useCallback(
		(item: BookingItem) => () => {
			setItemToDelete(item);
			setIsDialogOpen(true);
		},
		[]
	);

	const handleDelete = useCallback(() => {
		const { bookingId, isBlock: isBlockMessage } = itemToDelete as BookingItem;

		if (!bookingId) {
			return;
		}

		mutate({ id: bookingId, isBlockMessage });
		setIsDialogOpen(false);
	}, [itemToDelete?.bookingId, itemToDelete?.isBlock]);

	const showLoader = !isSuccess || isFetching || isLoading;

	return (
		<>
			<StyledList isEmploye={isEmployee(role)}>
				{showLoader ? (
					<LoaderWrapper>
						<Loader />
					</LoaderWrapper>
				) : (
					<Scrollbar
						customScrollbarWidth={3}
						scroll="vertical"
						maxHeight="100%"
						maxWidth="100%"
					>
						{data?.map((props) => (
							<Item
								office={office}
								key={props.bookingId}
								handleDelete={initiateBookDelete(props)}
								currentUserId={id}
								userRole={role as Roles}
								{...props}
							/>
						))}
					</Scrollbar>
				)}
			</StyledList>
			{itemToDelete && isDialogOpen && (
				<PopUp
					title={getDeleteDialogHeader(itemToDelete)}
					onClose={handleCloseDialog}
					type="approve"
					showCloseButton={false}
					headerIcon={itemToDelete.isBlock ? <Unblock /> : <CrossCircle />}
					controls={{
						negativeControl: { onClick: handleCloseDialog },
						positiveControl: { onClick: handleDelete },
					}}
				>
					<PopUpBody
						deskTitleAbriviation={deskTitleAbriviation}
						text={getDeleteDialogText(itemToDelete, id) || ''}
						textComponent={getDialogTextComponent(itemToDelete, id)}
						subtext={getDeleteDialogSubtext(itemToDelete, deskTitleAbriviation)}
					/>
				</PopUp>
			)}
		</>
	);
}
