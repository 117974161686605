export const Routes = {
	home: '/',
	dashboard: '/dashboard',
	reports: '/reports',
	timeSheet: '/time-sheet',
	clients: '/clients',
	projects: '/projects',
	users: '/users',
	signIn: '/sign-in',
	authCallback: '/auth-callback',
	signOut: '/sign-out',
	departments: '/departments',
	updateProfile: '/update-profile',
	setProfile: '/set-profile',
	booking: '/booking',
	equipment: '/equipment',
	adminPanel: '/admin-panel',
	all: '*',
};

export type RoutesType = typeof Routes;
