import { ToastPosition } from 'react-toastify';

import { useColorMode } from '@/context/Theme';

import { CloseButton } from './CloseButton';
import { StyledToastContainer } from './styles';

export function ToastContainer({ position }: { position: ToastPosition }) {
	const { theme } = useColorMode();

	// use "styles" instead "theme" attr because ToastContainer has its own "theme"
	return (
		<StyledToastContainer
			sx={{
				width: 384,
			}}
			position={position}
			icon={false}
			hideProgressBar
			closeOnClick
			draggable
			styles={theme}
			closeButton={CloseButton}
		/>
	);
}
