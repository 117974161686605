import { OfficeSelector } from '../OfficeSelector';

import { BookingList, BookingManagement } from '@/pages/Booking/components';

import {
	BookingsBlock,
	BookingsBody,
	BookingsTitle,
	BookingsHeaderRightMenuWrapper,
	BookingsHeaderWrapper,
	BookingBlockWrapper,
} from './styled';

export function BookingBlock() {
	return (
		<BookingBlockWrapper>
			<BookingsBlock>
				<BookingsHeaderWrapper>
					<BookingsTitle variant="small">Bookings</BookingsTitle>
					<BookingsHeaderRightMenuWrapper>
						<OfficeSelector />
					</BookingsHeaderRightMenuWrapper>
				</BookingsHeaderWrapper>
				<BookingsBody>
					<BookingManagement />
					<BookingList />
				</BookingsBody>
			</BookingsBlock>
		</BookingBlockWrapper>
	);
}
