import { Typography } from '@mms/mms-ui-library';

import { createPeriodInText } from '@/helpers/booking';

import { BodyWrapper, TextWrapper } from './styles';
import type { PopUpBodyProps } from './types';

export function PopUpBody({
	text,
	textComponent,
	subtext,
	date,
	desk,
	deskTitleAbriviation,
	isBlock,
}: PopUpBodyProps) {
	return (
		<BodyWrapper>
			<TextWrapper>
				{text && (
					<Typography variant="m-400" align="center">
						{text}
					</Typography>
				)}
				{textComponent && (
					<Typography variant="m-400" align="center">
						{textComponent}
					</Typography>
				)}
			</TextWrapper>
			<TextWrapper>
				{subtext && (
					<Typography variant="m-600" align="center">
						{subtext}
					</Typography>
				)}
			</TextWrapper>
			{date && desk && isBlock && (
				<Typography variant="m-600" align="center">
					From {createPeriodInText(0, date, isBlock)} - Desk{' '}
					{deskTitleAbriviation}-{desk}
				</Typography>
			)}
			{date && desk && !isBlock && (
				<Typography variant="m-600" align="center">
					{createPeriodInText(0, date)} - Desk {deskTitleAbriviation}-{desk}
				</Typography>
			)}
		</BodyWrapper>
	);
}
