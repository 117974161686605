import { Scrollbar } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { BOOKING_SMALL_MOBILE_SCREEN_WIDTH } from '../../constants';

import type { WrapperProps } from './types';

export const Wrapper = styled('div')({
	height: '100%',
	width: '100%',
	overflow: 'hidden',
});

export const StyledScrollbar = styled(Scrollbar)<WrapperProps>(
	({ theme, isManagerRoles }) => ({
		paddingTop: isManagerRoles ? theme.spaces.m : theme.spaces.s,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
			paddingLeft: 1,
			alignItems: 'unset',
		},
	})
);
