import { GridPaginationProps } from '@mms/mms-ui-library';

import { ROWS_PER_PAGE_OPTIONS } from '../constants';

// eslint-disable-next-line import/no-unused-modules
export const createGridPagination = (
	rowsLength: number,
	currentPage: number,
	onCurrentPageChange: (newPage: number) => void,
	rowsPerPage: number,
	onPerPageChange: (newPerPage: number) => void,
	total: number = 0,
	perPageOptions: number[] = ROWS_PER_PAGE_OPTIONS
): GridPaginationProps | undefined =>
	rowsLength
		? {
				currentPage,
				perPage: rowsPerPage,
				onPerPageChange,
				onCurrentPageChange,
				total,
				perPageOptions,
		  }
		: undefined;
