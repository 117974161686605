import { Sidebar } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { closedDrawerWidth } from '../config';

export const StyledSidebar = styled(Sidebar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, isOpen }) => ({
	marginTop: 54,

	[theme.breakpoints.down(860)]: {
		left: isOpen ? 0 : -closedDrawerWidth,
	},
}));
