import { SvgIconProps, createSvgIcon } from '@mui/material';

const NightIcon = createSvgIcon(
	<path
		id="Dark"
		d="M9.37237 4.92694C9.69767 4.70462 10.0324 4.51009 10.3742 4.3427C9.14365 7.28784 9.32991 10.7947 11.1704 13.6719C13.0133 16.5528 16.0766 18.1154 19.2005 18.0998C18.903 18.3772 18.5819 18.6352 18.2375 18.8705C14.5389 21.3983 9.55186 20.3578 7.09293 16.5138C4.62484 12.6554 5.65834 7.46534 9.37237 4.92694Z"
		stroke="currentColor"
		strokeWidth="1.5"
	/>,
	'Moon'
);

export function NightModeIcon({ sx, ...props }: SvgIconProps) {
	return (
		<NightIcon sx={{ fill: 'none', ...sx }} viewBox="0 0 24 24" {...props} />
	);
}
