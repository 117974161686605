import { Routes } from '@/constants/index';

export const HEADER_HEIGHT = 54;

export const HEADER_BURGER_DARK_THEME_HOVER = '#585C69';

export const bothScrollLayoutRoutes = [
	Routes.dashboard,
	Routes.reports,
	Routes.updateProfile,
	Routes.booking,
	Routes.timeSheet,
];
