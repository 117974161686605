import { styled } from '@mui/material/styles';

import {
	BOOKING_MOBILE_SCREEN_WIDTH,
	BOOKING_SMALL_MOBILE_SCREEN_WIDTH,
	BOOKING_TABS_CHANGE_SCREEN_WIDTH,
} from '../../constants';

import { ButtonsWrapperProps } from './types';

export const Wrapper = styled('div')();

export const ButtonsWrapper = styled('div')<ButtonsWrapperProps>(
	({ theme, isChiefRole }) => ({
		display: 'flex',
		justifyContent: isChiefRole ? 'space-between' : 'unset',
		gap: theme.spaces.s,
		width: 350,

		[theme.breakpoints.down(BOOKING_TABS_CHANGE_SCREEN_WIDTH)]: {
			paddingBottom: '240px',
		},

		[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
			paddingBottom: 0,
		},

		[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
			width: '100%',
		},
	})
);
