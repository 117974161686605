import { StyledComponent } from '@emotion/styled';

import { WarsawWorkplace, MinskWorkplace } from '../styled';

import {
	DeskDirectionRotateDegree,
	MAX_MINSK_SEATS,
	MAX_WARSAW_SEATS,
	MINSK_DESK_TITLE_ABRIVIATION,
	MINSK_WALLS_COUNT,
	WARSAW_DESK_TITLE_ABRIVIATION,
	WARSAW_WALLS_COUNT,
	minskBookingWorkplaceNumbersDirections,
	warsawBookingWorkplaceNumbersDirections,
} from '@/constants/booking';
import { LocationType, OfficeKnowledgeType } from '@/types/KnowledgeBase';

interface WorkplaceConfiguration {
	maxSeats: number;
	workplaceDirections: Record<number, DeskDirectionRotateDegree>;
	WorkplaceComponent: StyledComponent<any>;
	deskTitleAbriviation: string;
	wallsCount: number;
}

interface UseWorkplaceConfigurationOptions {
	office: OfficeKnowledgeType;
}

export const useWorkplaceConfiguration = ({
	office,
}: UseWorkplaceConfigurationOptions): WorkplaceConfiguration => {
	if (office.location === LocationType.Minsk) {
		return {
			deskTitleAbriviation: MINSK_DESK_TITLE_ABRIVIATION,
			maxSeats: MAX_MINSK_SEATS,
			workplaceDirections: minskBookingWorkplaceNumbersDirections,
			WorkplaceComponent: MinskWorkplace,
			wallsCount: MINSK_WALLS_COUNT,
		};
	}

	if (office.location === LocationType.Warsaw) {
		return {
			deskTitleAbriviation: WARSAW_DESK_TITLE_ABRIVIATION,
			maxSeats: MAX_WARSAW_SEATS,
			workplaceDirections: warsawBookingWorkplaceNumbersDirections,
			WorkplaceComponent: WarsawWorkplace,
			wallsCount: WARSAW_WALLS_COUNT,
		};
	}

	return {
		deskTitleAbriviation: MINSK_DESK_TITLE_ABRIVIATION,
		maxSeats: MAX_MINSK_SEATS,
		workplaceDirections: minskBookingWorkplaceNumbersDirections,
		WorkplaceComponent: MinskWorkplace,
		wallsCount: MINSK_WALLS_COUNT,
	};
};
