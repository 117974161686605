/* eslint-disable no-nested-ternary */
import axios, { AxiosError } from 'axios';
import { Moment } from 'moment';
import { UseMutationOptions, useMutation } from 'react-query';

import {
	NotificationType,
	GET_BOOKING_API_ENDPOINT,
	BOOKING_CREATED,
	RequestTypes,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { getDateAsResponseType } from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';

import { CreateMutationVariables } from './types';

export const useCreateBooking = (
	options: UseMutationOptions<void, AxiosError, CreateMutationVariables>
) => {
	const toast = useToast();

	return useMutation<void, AxiosError, CreateMutationVariables>({
		...options,
		mutationFn: ({
			requestType,
			workplaceNumber,
			fromDate,
			toDate,
			weeksInterval,
			officeId,
			userId = null,
		}) =>
			axios
				.post(GET_BOOKING_API_ENDPOINT, {
					requestType:
						requestType === RequestTypes.CreateBlock
							? RequestTypes.CreateBlock
							: userId
							? RequestTypes.CreateForUser
							: requestType,
					workplaceNumber,
					fromDate: getDateAsResponseType(fromDate as Moment),
					toDate: getDateAsResponseType(toDate as Moment),
					weeksInInterval: weeksInterval,
					userId,
					officeId,
				})
				.then(() => {
					toast.open(
						createToast(NotificationType.SUCCESS, null, BOOKING_CREATED)
					);
				}),
	});
};
