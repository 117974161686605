import moment from 'moment';
import {
	createContext,
	useReducer,
	useMemo,
	useCallback,
	useEffect,
	useContext,
	ComponentType,
} from 'react';

import { getInitialBookingOfficeByUserLocation } from '@/helpers/getInitialBookingOfficeByUserLocation';
import { getFullName } from '@/helpers/index';
import {
	DEFAULT_BOOKING_OFFICE,
	DEFAULT_EMPLOYEE,
} from '@/pages/Booking/constants';
import { Workplace } from '@/types/Booking';
import { OfficeKnowledgeType } from '@/types/KnowledgeBase';

import { useAuth } from './AuthContext';
import { useKnowledgeBase } from './KnowledgeBase';
import {
	BookingProviderProps,
	type Action,
	type BookingContextInitialValues,
	type BookingContextValues,
	type Payload,
	Employee,
	HighlightedDays,
} from './types';

const BookingContext = createContext({} as BookingContextValues);

const initialState: BookingContextInitialValues = {
	selected: 0,
	firstDay: moment().startOf('day'),
	lastDay: moment().startOf('day'),
	office: DEFAULT_BOOKING_OFFICE,
	initialOffice: null,
	employee: null,
	initialEmployee: DEFAULT_EMPLOYEE,
	error: null,
	highlightedDays: {
		bookedList: [],
		yourList: [],
		blockStartDate: null,
	},
	workplaces: [],
};

const {
	SET_SELECTED,
	SET_FIRST_DAY,
	SET_LAST_DAY,
	SET_EMPLOYEE,
	SET_OFFICE,
	SET_INITIAL_OFFICE,
	SET_HIGHLIGHTED_DAYS,
	SET_WORKPLACES,
	SET_ERROR,
	RESET_ON_OFFICE_CHANGE,
	INIT,
} = {
	SET_SELECTED: 'SET_SELECTED',
	SET_FIRST_DAY: 'SET_FIRST_DAY',
	SET_LAST_DAY: 'SET_LAST_DAY',
	SET_EMPLOYEE: 'SET_EMPLOYEE',
	SET_OFFICE: 'SET_OFFICE',
	SET_INITIAL_OFFICE: 'SET_INITIAL_OFFICE',
	SET_HIGHLIGHTED_DAYS: 'SET_HIGHLIGHTED_DAYS',
	SET_WORKPLACES: 'SET_WORKPLACES',
	SET_ERROR: 'SET_ERROR',
	RESET_ON_OFFICE_CHANGE: 'RESET_ON_OFFICE_CHANGE',
	INIT: 'INIT',
};

const reducer = (
	state: BookingContextInitialValues,
	{ type, payload }: Action
): BookingContextInitialValues => {
	switch (type) {
		case SET_SELECTED:
			return { ...state, selected: payload as number };
		case SET_FIRST_DAY:
			return { ...state, firstDay: payload as unknown as moment.Moment };
		case SET_LAST_DAY:
			return { ...state, lastDay: payload as unknown as moment.Moment };
		case SET_EMPLOYEE:
			return { ...state, employee: payload as Employee | null };
		case SET_OFFICE:
			return { ...state, office: payload as OfficeKnowledgeType };
		case SET_INITIAL_OFFICE:
			return { ...state, initialOffice: payload as OfficeKnowledgeType };
		case RESET_ON_OFFICE_CHANGE:
			return {
				...initialState,
				office: state.office,
				initialOffice: state.initialOffice,
				initialEmployee: state.initialEmployee,
			};
		case SET_HIGHLIGHTED_DAYS:
			return {
				...state,
				highlightedDays: payload as unknown as HighlightedDays,
			};
		case SET_WORKPLACES:
			return {
				...state,
				workplaces: payload as Workplace[],
			};
		case SET_ERROR:
			return {
				...state,
				error: payload as string | null,
				selected: 0,
			};

		case INIT:
			return {
				...state,
				selected: 0,
				employee: state.initialEmployee,
			};
		default:
			return state;
	}
};

function BookingProvider({
	children,
	initialBookingContextState,
}: BookingProviderProps) {
	const [
		{
			selected,
			firstDay,
			lastDay,
			employee,
			office,
			initialOffice,
			highlightedDays,
			workplaces,
			initialEmployee,
			error,
		},
		dispatch,
	] = useReducer(reducer, initialBookingContextState);

	const callback = useCallback(
		(type: string) => (payload: Payload) => dispatch({ type, payload }),
		[]
	);

	const selectedWorkplace = useMemo(
		() =>
			(workplaces as Array<Workplace>).find(
				(place: { workplaceNumber: number }) =>
					place.workplaceNumber === selected
			),
		[workplaces, selected]
	);

	useEffect(() => {
		callback(RESET_ON_OFFICE_CHANGE)(null);
	}, [office]);

	const value = useMemo(
		() =>
			({
				selected,
				firstDay,
				lastDay,
				employee,
				office,
				initialOffice,
				initialEmployee,
				highlightedDays,
				workplaces,
				error,
				selectedWorkplace,
				setSelected: callback(SET_SELECTED),
				setFirstDay: callback(SET_FIRST_DAY),
				setLastDay: callback(SET_LAST_DAY),
				setEmployee: callback(SET_EMPLOYEE),
				setOffice: callback(SET_OFFICE),
				setHighlightedDays: callback(SET_HIGHLIGHTED_DAYS),
				setWorkplaces: callback(SET_WORKPLACES),
				setError: callback(SET_ERROR),
				init: callback(INIT),
			} as unknown as BookingContextValues),
		[
			selected,
			firstDay,
			lastDay,
			employee,
			highlightedDays,
			workplaces,
			error,
			office,
		]
	);

	return (
		<BookingContext.Provider value={value}>{children}</BookingContext.Provider>
	);
}

export const useBookingContext = () => useContext(BookingContext);

export const withBookingProvider = (Component: ComponentType<any>) =>
	function WithBookingProvider() {
		const { id, firstName, lastName, location, rightPosition } = useAuth();

		const { knowledgeBase } = useKnowledgeBase();

		const initialOfficeValue: OfficeKnowledgeType | null = useMemo(
			() => getInitialBookingOfficeByUserLocation(knowledgeBase, location),
			[knowledgeBase, location]
		);

		const initialEmployeeValue: Employee | null = useMemo(() => {
			if (!id || !firstName || !lastName) {
				return null;
			}

			return {
				id,
				name: getFullName(firstName, lastName),
				positionDescription: rightPosition,
			};
		}, [id, firstName, lastName]);

		if (!initialOfficeValue || !initialEmployeeValue) {
			return null;
		}

		const initialBookingContextState: BookingContextInitialValues = {
			...initialState,
			office: initialOfficeValue,
			initialOffice: initialOfficeValue,
			initialEmployee: initialEmployeeValue,
			employee: initialEmployeeValue,
		};

		return (
			<BookingProvider initialBookingContextState={initialBookingContextState}>
				<Component />
			</BookingProvider>
		);
	};
