import { Scrollbar, ThemeMode } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { TimeSheetBreakpoints } from '@/pages/TimeSheet/constants';

import { closedDrawerWidth } from './config';
import { HEADER_BURGER_DARK_THEME_HOVER, HEADER_HEIGHT } from './constants';
import { MainContentProps } from './types';

const { TABLET_LG } = TimeSheetBreakpoints;

export const AppWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	paddingLeft: closedDrawerWidth,
	paddingTop: HEADER_HEIGHT,
	backgroundColor:
		theme.mode === ThemeMode.DARK
			? theme.palette['primary-11']
			: theme.palette['primary-2'],
	[theme.breakpoints.down(860)]: {
		paddingLeft: 0,
	},
}));

export const HeaderIconWrapper = styled('div')`
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;

	::before {
		content: '';
		position: absolute;
		z-index: -1;
		width: 0;
		height: 0;
		border-radius: 50%;
		background: transparent;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: 200ms ease;
	}

	:hover::before {
		width: 32px;
		height: 32px;
		transition: 200ms ease;
		background: ${({ theme }) =>
			theme.mode === ThemeMode.LIGHT
				? theme.palette['primary-8']
				: HEADER_BURGER_DARK_THEME_HOVER};
	}
`;

export const MainContent = styled('main')<MainContentProps>(
	({ theme, withPaddings }) => ({
		paddingTop: withPaddings ? theme.spaces.m : 0,
		paddingBottom: withPaddings ? theme.spaces.l : 0,
		position: 'relative',
		flexGrow: 1,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			padding: withPaddings ? theme?.spacing(0.5) : 0,
		},
		'&:after': {
			content: '""',
			display: 'block',
			height: theme.spaces.l,
		},
	})
);

export const StyledScroll = styled(Scrollbar)(({ theme }) => ({
	width: '100%',
	height: '100%',
	padding: `0 ${theme.spaces.l}px`,
	scrollMarginBottom: 16,

	[theme.breakpoints.down(TABLET_LG)]: {
		padding: `0 ${theme.spaces.m}px`,
	},
}));

export const LoaderWrapper = styled('div')({
	position: 'absolute',
	width: '100%',
	top: 54,
	left: 0,
	zIndex: 1500,
});
