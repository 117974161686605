import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_USERS_API_ENDPOINT,
	Roles,
	isManagerRole,
	isPMO,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import type { LookupFilterUserResponse } from '@/types/UserInfo';

import { UsersLookupFiltersParams } from './types';

const KEY_TIMESHEET = 'users-lookup-timesheet';
const KEY_REPORTS = 'users-lookup-timesheet';

export const useUsersLookupFilters = (
	{
		keyWord,
		clientId,
		projectId,
		departmentId,
	}: Partial<UsersLookupFiltersParams>,
	toast: ToastContextValues,
	role: Roles,
	isReports = false,
	onlyManagedUsers?: boolean,
	userDepartmentId?: string
) =>
	useQuery({
		queryKey: [
			isReports ? KEY_TIMESHEET : KEY_REPORTS,
			keyWord,
			clientId,
			projectId,
			departmentId,
			userDepartmentId,
			onlyManagedUsers,
		],
		queryFn: () =>
			axios
				.get<Array<LookupFilterUserResponse>>(
					`${GET_USERS_API_ENDPOINT}/lookup/filters${
						isReports ? '/reports' : ''
					}`,
					{
						params: {
							keyWord,
							clientId,
							projectId,
							departmentId,
							onlyManagedUsers: onlyManagedUsers ? true : undefined,
						},
					}
				)
				.then((res) => res.data)
				.catch((error) => {
					if (error?.response?.data?.Message) {
						toast.open(
							createToast(
								NotificationType.ERROR,
								null,
								error.response.data.Message
							)
						);
					}
				}),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: isManagerRole(role) || isPMO(userDepartmentId),
	});
