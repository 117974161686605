import { Loader } from '@mms/mms-ui-library';
import Backdrop from '@mui/material/Backdrop';
import moment from 'moment';
import { memo, useCallback, useMemo } from 'react';

import { WORKPLACE_ELEMENT_ID } from '../../constants';

import { WarningIcon } from '@/assets/icons/Warning';
import { useBookingContext } from '@/context/Booking';
import { useFilledDesksWithRangeDate } from '@/hooks/useFilledDesksWithRangeDate';
import { LocationType } from '@/types/KnowledgeBase';

import { OFFICE_CLOSED_MESSAGE } from './constants';
import { Desk } from './Desk';
import { useWorkplaceConfiguration } from './hooks/useWorkplaceConfiguration';
import { Legend } from './Legend';
import {
	BookingWorkplaceWrapper,
	OfficeWarningTypograpphy,
	OfficeWarningContainer,
	Title,
	Wall,
	WorkplaceLoaderWrapper,
	WorkplaceWrapper,
	OfficeWarningMessageContainer,
} from './styled';
import type { DeskType } from './types';

function BookingWorkplaceInner() {
	const {
		selected,
		setSelected,
		workplaces,
		firstDay,
		lastDay,
		error,
		office,
		setWorkplaces,
	} = useBookingContext();

	const { showDesks } = useFilledDesksWithRangeDate(
		moment(firstDay),
		moment(lastDay),
		setWorkplaces,
		error,
		office
	);
	const handleClick = useCallback(
		(workplaceNumber: number) => {
			if (lastDay) {
				setSelected(workplaceNumber);
			}
		},
		[lastDay]
	);

	const {
		maxSeats,
		workplaceDirections,
		WorkplaceComponent,
		deskTitleAbriviation,
		wallsCount,
	} = useWorkplaceConfiguration({ office });

	const desks = useMemo(
		() =>
			Array.from({ length: maxSeats }, (_, i) => ({
				workplaceNumber: i + 1,
			})).reduce((acc, desk) => {
				const newDesk: DeskType = workplaces.find(
					({ workplaceNumber }) => workplaceNumber === desk.workplaceNumber
				) || { ...desk };

				const rotationDegree = workplaceDirections[newDesk.workplaceNumber];

				acc.push(
					<Desk
						{...newDesk}
						rotationDegree={rotationDegree}
						deskTitle={`${deskTitleAbriviation}-${newDesk.workplaceNumber}`}
						key={newDesk.workplaceId ?? `desk-${newDesk.workplaceNumber}`}
						number={newDesk.workplaceNumber}
						onClick={handleClick}
						isSelected={selected === desk.workplaceNumber}
					/>
				);

				return acc;
			}, [] as JSX.Element[]),
		[selected, workplaces]
	);

	if (office.location === LocationType.Minsk) {
		return (
			<OfficeWarningContainer>
				<OfficeWarningMessageContainer>
					<WarningIcon />
					<OfficeWarningTypograpphy variant="m-400">
						{OFFICE_CLOSED_MESSAGE.firstSentence}
						<br />
						{OFFICE_CLOSED_MESSAGE.secondSentence}
					</OfficeWarningTypograpphy>
				</OfficeWarningMessageContainer>
			</OfficeWarningContainer>
		);
	}

	if (!showDesks) {
		return (
			<WorkplaceLoaderWrapper>
				<Loader />
			</WorkplaceLoaderWrapper>
		);
	}

	return (
		<BookingWorkplaceWrapper>
			<Title variant="large">Desk booking</Title>
			<WorkplaceWrapper scroll="both" maxHeight="100%" maxWidth="100%">
				<WorkplaceComponent id={WORKPLACE_ELEMENT_ID}>
					{desks}
					{new Array(wallsCount).fill(<Wall />)}
					{error && <Backdrop sx={{ position: 'absolute' }} open invisible />}
				</WorkplaceComponent>
			</WorkplaceWrapper>
			<Legend />
		</BookingWorkplaceWrapper>
	);
}

export const BookingWorkplace = memo(BookingWorkplaceInner);
