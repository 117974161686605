import { Box, Stack, styled } from '@mui/material';

import { ThemeMode } from '@/theme';

export const SignInStack = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	gap: theme.spacing(2),
	height: 42.25,
	alignItems: 'center',
	position: 'relative',
	zIndex: 9999999,
}));

export const SignInWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	backgroundColor:
		theme.mode === ThemeMode.DARK
			? theme.palette['primary-11']
			: theme.palette['primary-2'],
	justifyContent: 'center',
	alignContent: 'center',
	height: '100vh',
	position: 'relative',
	padding: 0,
	overflow: 'hidden',
}));

export const Wrapper = styled(Box)({
	display: 'flex',
	maxWidth: 'fit-content',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
});
