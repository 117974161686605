import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import {
	NotificationType,
	GET_BOOKING_API_ENDPOINT,
	SOMETHING_WENT_WRONG,
	BOOKING_CANCELED,
	DESC_UNBLOCKED,
	HttpStatuses,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

import { DeleteMutationVariables } from './types';

export const useDeleteBooking = (
	options: UseMutationOptions<void, AxiosError, DeleteMutationVariables>,
	toast: ToastContextValues
) =>
	useMutation<void, AxiosError, DeleteMutationVariables>({
		...options,
		mutationFn: ({ id, isBlockMessage }) =>
			axios
				.delete(`${GET_BOOKING_API_ENDPOINT}/${id}`)
				.then(() => {
					toast.open(
						createToast(
							NotificationType.SUCCESS,
							null,
							isBlockMessage ? DESC_UNBLOCKED : BOOKING_CANCELED
						)
					);
				})
				.catch((error: unknown) => {
					if (
						error instanceof AxiosError &&
						error.response?.status !== HttpStatuses.NotFound
					) {
						toast.open(
							createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
						);
					}
				}),
	});
