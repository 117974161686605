import { Typography } from '@mms/mms-ui-library';
import { alpha } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';

import { Avatar } from '@/components/Avatar';
import { BOOKING_DESK_SEAT_WIDTH } from '@/pages/Booking/constants';
import { ThemeMode } from '@/theme';

import type {
	ColorType,
	DeskTooltipTextWrapperProps,
	StyledDeskProps,
} from './types';
import { getRotatedSeatStyles } from './utils';

const cssHelper = (func: (param: ColorType) => object, param: ColorType) => ({
	...func(param),
	'&:hover': {
		cursor: 'unset',
	},
	'&:active': {},
});

const checkCamelCase = (prop: string) =>
	!['isBlocked', 'isBooked', 'isRotated', 'isBookedByUser'].includes(prop);

const getPlaceColor = (
	isBlocked: boolean,
	isBooked: boolean,
	isBookedByUser: boolean,
	theme: Theme
) => {
	if (!isBlocked && !isBooked && !isBookedByUser) {
		return theme.palette['accent-6'];
	}

	return theme.palette['exc-primaryHeader-primary-1'];
};

export const StyledDesk = styled('div', {
	shouldForwardProp: checkCamelCase,
})<StyledDeskProps>(
	({
		theme,
		isBlocked,
		isBooked,
		isBookedByUser,
		rotationDegree,
		selected,
		number,
	}) => {
		const setColor = (color: string) => ({
			backgroundColor: color,
			h6: {
				color: theme.palette['primary-1'],
			},
		});

		return {
			display: 'flex',
			gridArea: `desk-${number}`,
			flexDirection: 'row-reverse',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
			border: `2px solid ${theme.palette['primary-1']}`,
			borderRadius: 14,
			backgroundColor: theme.palette['base-2'],
			boxShadow: 'none',
			padding: theme.spacing(1),
			position: 'relative',
			transition: 'all .3s ease-in-out',
			'& h6': {
				color: theme.palette['base-4'],
			},
			'&:after': {
				content: '""',
				position: 'absolute',
				backgroundColor: theme.palette['base-2'],
				width: BOOKING_DESK_SEAT_WIDTH,
				height: 26,
				...getRotatedSeatStyles(rotationDegree),
				borderTopRightRadius: '100%',
				borderBottomRightRadius: '100%',
			},
			'&:hover': {
				cursor: 'pointer',
				borderColor: theme.palette['primary-1'],
				...(!isBlocked &&
					!isBooked &&
					!isBookedByUser &&
					!selected && {
						backgroundColor:
							theme.mode === ThemeMode.LIGHT
								? theme.palette['primary-4']
								: theme.palette['primary-7'],
					}),
			},
			'&:active': {
				backgroundColor: theme.palette['base-2'],
				borderColor: theme.palette['accent-6'],
			},
			...(isBlocked && {
				...cssHelper(setColor, theme.palette['feedback-info']),
				opacity: 0.7,
			}),
			...(isBooked && {
				...cssHelper(setColor, theme.palette['feedback-success']),
				opacity: 0.7,
			}),
			...(isBookedByUser && {
				...cssHelper(setColor, theme.palette['accent-6']),
				opacity: 0.7,
			}),
			...(selected && {
				borderColor: theme.palette['accent-6'],
				'&:hover': {
					cursor: 'pointer',
					borderColor: theme.palette['accent-6'],
					backgroundColor: alpha(theme.palette.bookingWorkplace.selected, 0.5),
				},
				h6: {
					color: getPlaceColor(
						Boolean(isBlocked),
						isBooked,
						isBookedByUser,
						theme
					),
				},
			}),
		};
	}
);

export const DeskTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette['base-4'],
	userSelect: 'none',

	[theme.breakpoints.down(1470)]: {
		fontSize: '10px',
	},
}));

export const BookedAvatar = styled(Avatar)(({ theme }) => ({
	justifyContent: 'flex-start',
	width: 'fit-content',
	height: 'fit-content',
	fontSize: '16px',
	lineHeight: '20px',
	backgroundColor: 'transparent',
	borderRadius: 0,
	color: theme.palette['primary-1'],

	[theme.breakpoints.down(1470)]: {
		fontSize: '12px',
		lineHeight: '16px',
	},
}));

export const DeskTooltipTextWrapper = styled(
	'div'
)<DeskTooltipTextWrapperProps>(({ isMobile }) => ({
	display: 'inline-block',
	whiteSpace: 'pre-wrap',
	maxWidth: isMobile ? '15ch' : '43ch',
	wordWrap: 'break-word',
}));
