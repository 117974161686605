import moment, { Moment } from 'moment';
import { useEffect, Dispatch, SetStateAction } from 'react';

import { filterUniqueWorkplaces } from '@/helpers/filterUniqueWorkplaces';
import { useGetReservation } from '@/queries/booking';
import { Workplace } from '@/types/Booking';
import { OfficeKnowledgeType } from '@/types/KnowledgeBase';

export const useFilledDesksWithRangeDate = (
	firstDay: Moment,
	lastDay: Moment,
	setWorkplaces: Dispatch<SetStateAction<Workplace[]>>,
	error: string | null,
	office: OfficeKnowledgeType
) => {
	const { data, dataUpdatedAt, isFetching, isLoading } = useGetReservation({
		fromDate: moment(firstDay)
			.clone()
			.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.toISOString(true),
		toDate: moment(lastDay)
			.clone()
			.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.toISOString(true),
		error,
		officeId: office.id,
	});

	useEffect(() => {
		if (data) {
			const newWorkplaces = data.reduce(
				(acc: Workplace[], dataObj) => [...acc, ...dataObj.userWorkplaces],
				[]
			);
			const filteredUniqueWrokplaces = filterUniqueWorkplaces(newWorkplaces);

			setWorkplaces(filteredUniqueWrokplaces);
		}
	}, [dataUpdatedAt, data]);

	const showDesks = !isFetching && !isLoading;

	return { showDesks };
};
