import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { BOOKING_MOBILE_SCREEN_WIDTH } from '@/pages/Booking/constants';

export const LegendWrapper = styled('div')(({ theme }) => ({
	gridArea: 'legend',
	display: 'flex',
	gap: theme.spaces.m,
	marginTop: 'auto',

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		marginTop: '0',
	},
}));

export const SquareWrapper = styled('div')({
	display: 'flex',
	width: 'min-content',
	height: 24,
	transition: 'all 3s ease-in-out',
	cursor: 'pointer',
	overflow: 'hidden',
	'&:hover': {
		width: 'fit-content !important',
		p: {
			opacity: 1,
			width: 'fit-content',
			marginLeft: 8,
		},
	},
	p: {
		whiteSpace: 'nowrap',
		width: 0,
		opacity: 0,
		transition: 'all .3s ease-in-out',
		'&:first-letter': {
			textTransform: 'uppercase',
		},
	},
});

export const LegendTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette['base-6'],
}));

export const ColorTooltipTextWrapper = styled('div')(() => ({
	'&:first-letter': {
		textTransform: 'uppercase',
	},
}));
