import { KnowledgeBase, LocationType } from '@/types/KnowledgeBase';

export const getInitialBookingOfficeByUserLocation = (
	knowledgeBase: KnowledgeBase,
	location: LocationType
) => {
	const userOffice = knowledgeBase?.Offices?.find(
		(office) => office.location === location
	);

	if (userOffice) {
		return userOffice;
	}

	if (location === LocationType.Minsk || location === LocationType.Vitebsk) {
		const nearestOffice = knowledgeBase?.Offices?.find(
			(office) => office.location === LocationType.Minsk
		);

		if (nearestOffice) {
			return nearestOffice;
		}
	}

	if (location === LocationType.Warsaw || location === LocationType.Tbilisi) {
		const nearestOffice = knowledgeBase?.Offices?.find(
			(office) => office.location === LocationType.Warsaw
		);

		if (nearestOffice) {
			return nearestOffice;
		}
	}

	return null;
};
