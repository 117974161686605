import { useTheme } from '@emotion/react';
import { DatePicker } from '@mms/mms-ui-library';
import { PickersDayProps } from '@mui/x-date-pickers';
import type { DayCalendarSlotsComponentsProps } from '@mui/x-date-pickers/internals';
import moment, { Moment, MomentInput } from 'moment';
import {
	ComponentType,
	PropsWithChildren,
	SyntheticEvent,
	memo,
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useQueryClient } from 'react-query';

import { getBlockedMonthDays } from '../../utils';

import {
	BookingQueriesKeys,
	DAY_AFTER_THREE_MONTHS,
	FIELD_IS_REQUIRED,
	INVALID_DATE_RANGE,
	currentDate,
} from '@/constants/index';
import { useBookingContext } from '@/context/Booking';
import { CalendarHighlightedDays, HighlightedDays } from '@/context/types';
import {
	getEndOfMonthForRequest,
	getStartOfMonthForRequest,
} from '@/helpers/booking';

import { Day } from './Day';
import {
	BookingTimeWrapper,
	DatePickersWrapper,
	StyledCalendarUI,
} from './styled';

function BookingTimeInner({ children }: PropsWithChildren) {
	const {
		firstDay,
		setFirstDay,
		lastDay,
		setLastDay,
		highlightedDays,
		setSelected,
		setError,
		error,
	} = useBookingContext();
	const [disabled, setDisabled] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [hoveredDateRange, setHoveredDateRange] = useState<(Moment | null)[]>(
		[]
	);

	const isUserClick = useRef(false);
	const theme = useTheme();

	const [endOfMonth, setEndOfMonth] = useState(
		getEndOfMonthForRequest(currentDate)
	);

	const [startOfMonth, setStartOfMonth] = useState(
		getStartOfMonthForRequest(currentDate)
	);

	const newHighlightedDays: Record<keyof CalendarHighlightedDays, number[]> =
		useMemo(() => {
			const result: CalendarHighlightedDays = {
				bookedList: [],
				yourList: [],
				blockedList: [],
			};
			Object.keys(highlightedDays).forEach((key) => {
				if (key === 'blockStartDate') {
					const blockStartDate = highlightedDays.blockStartDate;

					if (
						blockStartDate === null ||
						lastDay?.isBefore(blockStartDate, 'month')
					) {
						result.blockedList = [];

						return;
					}

					const blockedDays = getBlockedMonthDays();
					result.blockedList = lastDay?.isSame(blockStartDate, 'month')
						? blockedDays.filter((day) => day >= blockStartDate.date())
						: blockedDays;
				} else {
					result[key as keyof CalendarHighlightedDays] = highlightedDays[
						key as keyof Omit<HighlightedDays, 'blockStartDate'>
					]
						.filter((day: MomentInput) =>
							moment(day).isBetween(startOfMonth, endOfMonth, 'day', '[]')
						)
						.map((day: MomentInput) => moment(day).date());
				}
			});

			return result;
		}, [highlightedDays, startOfMonth, endOfMonth, lastDay]);

	const isSameDate = moment(firstDay).isSame(moment(lastDay), 'day');

	const queryClient = useQueryClient();

	const handleMonthChange = useCallback((days: Moment) => {
		setEndOfMonth(getEndOfMonthForRequest(days));
		setStartOfMonth(getStartOfMonthForRequest(days));
		queryClient.cancelQueries([BookingQueriesKeys.reservations]);
	}, []);

	const getAvailableDateRange = useCallback(
		(day: Moment | null) => {
			if (day?.isBefore(currentDate, 'day')) {
				return [currentDate, firstDay];
			}

			if (day?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				return [firstDay, DAY_AFTER_THREE_MONTHS];
			}

			const dateRange = [day, firstDay];

			return day?.isBefore(firstDay) ? dateRange : dateRange.reverse();
		},
		[firstDay]
	);

	const handleMouseEnterDay = useCallback(
		(event: SyntheticEvent, day: unknown) => {
			if (!hovered) {
				return;
			}

			if ((day as Moment).isSame(firstDay)) {
				setHoveredDateRange([]);

				return;
			}

			setHoveredDateRange(getAvailableDateRange(day as Moment));
		},
		[hovered, firstDay]
	);

	const handleMouseLeaveDay = useCallback(() => {
		setHoveredDateRange([]);
	}, []);

	const handleChangeFirstDay = useCallback(
		(newValue: Date | null) => {
			if (!newValue) {
				setFirstDay(null);
				setLastDay(null);
				setDisabled(true);
				setError('invalidDate');

				return;
			}

			const momentValue = moment(newValue).startOf('day');

			if (momentValue?.isBefore(currentDate.clone(), 'day')) {
				setFirstDay(momentValue);
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setFirstDay(momentValue);
				setError('maxDate');

				return;
			}

			if (!momentValue?.isValid()) {
				setLastDay(momentValue);
				setError('pastDate');
				setDisabled(true);

				return;
			}

			if (momentValue.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}

			setDisabled(false);
			setError(null);
			setFirstDay(momentValue);

			if (!momentValue.isSame(firstDay)) {
				setLastDay(momentValue);
			}
			setSelected(0);
		},
		[disabled, firstDay]
	);

	const handleChangeLastDay = useCallback(
		(newValue: Date | null) => {
			if (!newValue) {
				setLastDay(null);
				setError('invalidDate');

				return;
			}
			const momentValue = moment(newValue);

			if (momentValue?.isBefore(firstDay, 'day')) {
				setLastDay(momentValue);
				setError('minDate');

				return;
			}

			if (momentValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setLastDay(momentValue);
				setError('maxDate');

				return;
			}

			if (momentValue?.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}

			if (firstDay?.isBefore(moment(), 'day')) {
				setLastDay(momentValue);
				setError('pastDate');

				return;
			}
			setError(null);
			setLastDay(momentValue);
		},
		[firstDay, lastDay, setError, setLastDay]
	);

	// If you want to change management to date from inputs to calendar, use handleChange instead handleChangeFirstDay, handleChangeLastDay and disable inputs.
	const handleChange = useCallback(
		(newValue: Moment | null) => {
			if (!lastDay && firstDay && newValue?.isBefore(firstDay, 'day')) {
				setFirstDay(newValue);
				setLastDay(firstDay);
				setHovered(false);
				setHoveredDateRange([]);
				isUserClick.current = false;

				return;
			}

			if (!firstDay || lastDay) {
				setFirstDay(newValue);
				setHovered(true);
				setLastDay(null);
			} else {
				setLastDay(newValue);
				setHovered(false);
				setHoveredDateRange([]);
			}
			setEndOfMonth(getEndOfMonthForRequest(newValue));
			setStartOfMonth(getStartOfMonthForRequest(newValue));
			setSelected(0);
			isUserClick.current = false;
		},
		[firstDay, lastDay, setFirstDay, setLastDay]
	);

	const errorMessage = useMemo(() => {
		switch (error) {
			case 'pastDate':
			case 'maxDate':
			case 'minDate': {
				return INVALID_DATE_RANGE;
			}

			case 'invalidDate': {
				return FIELD_IS_REQUIRED;
			}

			default: {
				return undefined;
			}
		}
	}, [error, setSelected]);

	const datePickerStylesConfig = useMemo(
		() => ({
			label: {
				background: theme.palette['primary-1'],
			},
			errorText: {
				wrapper: {
					background: theme.palette['primary-1'],
				},
			},
		}),
		[theme]
	);

	return (
		<BookingTimeWrapper>
			{children}
			<StyledCalendarUI
				value={lastDay}
				onChange={handleChange}
				onMonthChange={handleMonthChange}
				slots={{ day: Day as ComponentType<PickersDayProps<Moment>> }}
				slotProps={{
					day: {
						selectedDay: currentDate.clone(),
						highlightedDays: {
							...newHighlightedDays,
						},
						firstDay,
						lastDay,
						isSameDate,
						hovered,
						hoveredDateRange,
						onMouseEnter: handleMouseEnterDay,
						onMouseLeave: handleMouseLeaveDay,
					} as DayCalendarSlotsComponentsProps<Moment>,
				}}
				minDate={currentDate}
				maxDate={DAY_AFTER_THREE_MONTHS}
				disabled
				disablePast
			/>
			<DatePickersWrapper>
				<DatePicker
					label="From"
					value={firstDay?.toDate() || null}
					onChange={handleChangeFirstDay}
					errorText={errorMessage}
					minDate={currentDate.toDate()}
					maxDate={DAY_AFTER_THREE_MONTHS.toDate()}
					calendarPosition="bottom-start"
					stylesConfig={datePickerStylesConfig}
				/>
				<DatePicker
					label="To"
					value={lastDay?.toDate() || null}
					disabled={disabled}
					onChange={handleChangeLastDay}
					calendarPosition="bottom-start"
					minDate={firstDay ? firstDay.toDate() : undefined}
					maxDate={DAY_AFTER_THREE_MONTHS.toDate()}
					errorText={errorMessage}
					stylesConfig={datePickerStylesConfig}
				/>
			</DatePickersWrapper>
		</BookingTimeWrapper>
	);
}

export const BookingTime = memo(BookingTimeInner);
