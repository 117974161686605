import axios from 'axios';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_COLLISIONS_API_ENDPOINT,
	BookingQueriesKeys,
} from '@/constants/index';
import { useToast } from '@/context/index';
import {
	getTextFromMultipleDate,
	getDateAsResponseType,
} from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';

interface UseCheckCollisionsOptions {
	workplaceNumber: number;
	fromDate: Moment | null;
	toDate: Moment | null;
	weeksInterval: number;
	officeId: string;
	deskTitleAbriviation: string;
	userId: number;
}

export const useCheckCollisions = ({
	workplaceNumber,
	fromDate,
	toDate,
	weeksInterval,
	officeId,
	deskTitleAbriviation,
	userId,
}: UseCheckCollisionsOptions) => {
	// Prevent check for collisions on office change
	const [prevOfficeId, setPrevOfficeId] = useState(officeId);
	const toast = useToast();

	useEffect(() => {
		setPrevOfficeId(officeId);
	}, [officeId]);

	return useQuery({
		queryKey: [
			BookingQueriesKeys.collisions,
			workplaceNumber,
			fromDate,
			toDate,
			weeksInterval,
			officeId,
			userId,
		],
		queryFn: async () => {
			if (workplaceNumber > 0 && prevOfficeId === officeId) {
				try {
					const response = await axios.get(GET_COLLISIONS_API_ENDPOINT, {
						params: {
							officeId,
							workplaceNumber,
							fromDate: getDateAsResponseType(fromDate as Moment),
							toDate: getDateAsResponseType(toDate as Moment),
							weeksInterval,
							userId,
						},
					});

					if (response.data?.length > 0) {
						const dates = getTextFromMultipleDate(response.data);

						toast.open(
							createToast(
								NotificationType.INFO,
								null,
								`You cannot book it, because the workplace ${deskTitleAbriviation}-${workplaceNumber} is booked for the following period: ${dates}`
							)
						);

						return true;
					}
				} catch {
					return true;
				}

				return false;
			}

			return false;
		},
		keepPreviousData: true,
		enabled: Boolean(fromDate) && Boolean(toDate),
		refetchOnWindowFocus: false,
	});
};
