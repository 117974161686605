import { LocationType } from '@/types/KnowledgeBase';

import { OfficeSelectorOption } from './types';

export const bookingTitleAbriviations: Record<string, string> = {
	[LocationType.Warsaw]: 'W',
	[LocationType.Minsk]: 'M',
};

export const bookingOfficeNames: Record<string, string> = {
	[LocationType.Warsaw]: 'Office W',
	[LocationType.Minsk]: 'Office M',
};
export const officeSelectorOptions: Array<OfficeSelectorOption> = [
	{
		name: 'Office W',
		officeLocation: LocationType.Warsaw,
	},
	{
		name: 'Office M',
		officeLocation: LocationType.Minsk,
	},
];
