import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { ServerResponseError } from '../types';

import {
	SOMETHING_WENT_WRONG,
	NotificationType,
	GET_NOTIFICATIONS,
	BookingQueriesKeys,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';
import { BookingNotification } from '@/types/Booking';

const NOTIFICATION_MARKED = 'Your notification has been marked as read.';

export const useReadNotification = () => {
	const queryClient = useQueryClient();
	const toast = useToast();

	return useMutation<unknown, AxiosError<ServerResponseError>, number>({
		mutationFn: async (notificationId) => {
			await axios.post(`${GET_NOTIFICATIONS}/${notificationId}/read`);
		},
		onSuccess(data, notificationId) {
			toast.open(
				createToast(NotificationType.SUCCESS, null, NOTIFICATION_MARKED)
			);
			queryClient.setQueryData<BookingNotification[]>(
				BookingQueriesKeys.notifications,
				(prevNotifications: BookingNotification[] | undefined) => {
					if (prevNotifications) {
						return prevNotifications.filter(
							(notification) => notification.notificationId !== notificationId
						);
					}

					return [];
				}
			);
		},
		onError(error) {
			toast.open(
				createToast(
					NotificationType.ERROR,
					null,
					error.response?.data?.Message || SOMETHING_WENT_WRONG
				)
			);
		},
	});
};
