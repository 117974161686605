import { SvgIconProps, createSvgIcon } from '@mui/material';

const SignOut = createSvgIcon(
	<path
		id="Log out_2"
		d="M4 9V5.77778C4 4.79594 4.79594 4 5.77778 4H18.2222C19.2041 4 20 4.79594 20 5.77778V18.2222C20 19.2041 19.2041 20 18.2222 20H5.77778C4.79594 20 4 19.2041 4 18.2222V15M4 12H14M14 12L10 8M14 12L10 16"
		stroke="currentColor"
		strokeWidth="1.33333"
		strokeLinecap="round"
	/>,
	'Sign out'
);

export function SignOutIcon({ sx, ...props }: SvgIconProps) {
	return (
		<SignOut sx={{ fill: 'none', ...sx }} viewBox="0 0 24 24" {...props} />
	);
}
