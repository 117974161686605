export const BookPlaceType = {
	Booked: 'booked',
	Selected: 'selected',
	Blocked: 'blocked',
	YourBlocked: 'yourBlocked',
};

export const BookType = {
	Single: 'single',
	Repetitive: 'repetitive',
	Multiple: 'multiple',
};

export const REPEAT_WEEKS_MIN_VALUE = 1;
export const REPEAT_WEEKS_MAX_VALUE = 4;

export enum RequestTypes {
	CreateSelf = 'CreateSelf',
	CreateBlock = 'CreateBlock',
	CreateForUser = 'CreateForUser',
}

export const BLOCK_DIALOG_HEADER = 'DESK BLOCKING';
export const UNBLOCK_DIALOG_HEADER = 'DESK UNBLOCKING';
export const CANCEL_DIALOG_HEADER = 'BOOKING CANCELING';

export enum DeskDirectionRotateDegree {
	Left = 0,
	Bottom = 270,
	Right = 180,
	Top = 90,
}

const { Left, Bottom, Right, Top } = DeskDirectionRotateDegree;

export const minskBookingWorkplaceNumbersDirections: Record<
	number,
	DeskDirectionRotateDegree
> = {
	1: Right,
	2: Left,
	3: Right,
	4: Left,
	5: Right,
	6: Left,
	7: Left,
	8: Right,
	9: Left,
	10: Right,
	11: Left,
	12: Right,
	13: Left,
	14: Right,
	15: Right,
	16: Right,
	17: Right,
	18: Left,
	19: Left,
	20: Left,
	21: Left,
	22: Right,
	23: Right,
	24: Right,
	25: Right,
	26: Right,
	27: Left,
	28: Left,
	29: Left,
	30: Left,
	31: Left,
};

export const warsawBookingWorkplaceNumbersDirections: Record<
	number,
	DeskDirectionRotateDegree
> = {
	1: Bottom,
	2: Top,
	3: Bottom,
	4: Left,
	5: Top,
	6: Right,
	7: Left,
	8: Right,
	9: Top,
};

export const COLORS_ENUM = {
	Available: 'available',
	Blocked: 'blocked',
	Booked: 'booked',
	Selected: 'selected',
};
export const COLORS = Object.values(COLORS_ENUM);

export const MAX_MINSK_SEATS = 31;
export const MAX_WARSAW_SEATS = 9;

export const MINSK_WALLS_COUNT = 1;
export const WARSAW_WALLS_COUNT = 0;

export const MINSK_DESK_TITLE_ABRIVIATION = 'M';
export const WARSAW_DESK_TITLE_ABRIVIATION = 'W';
