import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material';

import { BOOKING_MOBILE_SCREEN_WIDTH } from '../../constants';

export const OfficeSelectorChevronWrapper = styled('div')<{ isOpen: boolean }>(
	({ isOpen, theme }) => ({
		transform: isOpen ? 'none' : 'rotate(180deg)',
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
			color: theme.palette['base-6'],
		},
	})
);

export const OfficeSelectorWrapper = styled('div')(() => ({
	display: 'flex',
	gap: 4,
	cursor: 'pointer',
}));

export const OfficeName = styled(Typography)(({ theme }) => ({
	color: theme.palette['exc-primaryHeader-primary-1'],

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		color: theme.palette['base-6'],
	},

	[theme.breakpoints.down('sm')]: {
		fontSize: theme.typography.pxToRem(14),
	},
}));
