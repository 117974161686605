import { SvgIconProps, createSvgIcon } from '@mui/material';

const Profile = createSvgIcon(
	<path
		id="Union"
		fillRule="evenodd"
		clipRule="evenodd"
		d="M15.9047 9.33298C15.9047 11.4303 14.175 13.166 11.9973 13.166C9.81959 13.166 8.08984 11.4303 8.08984 9.33298C8.08984 7.23562 9.81959 5.5 11.9973 5.5C14.175 5.5 15.9047 7.23562 15.9047 9.33298ZM14.8701 13.8519C16.3927 12.9085 17.4047 11.2373 17.4047 9.33298C17.4047 6.38766 14.9837 4 11.9973 4C9.01084 4 6.58984 6.38766 6.58984 9.33298C6.58984 11.2375 7.60211 12.9088 9.12498 13.8522C6.47187 14.8435 4.48341 17.1677 4 19.9961H5.52771C6.15432 17.0611 8.79865 14.8359 11.998 14.8359C15.1973 14.8359 17.8416 17.0611 18.4682 19.9961H19.996C19.5125 17.1674 17.5236 14.843 14.8701 13.8519Z"
		fill="currentColor"
	/>,
	'Profile'
);

export function ProfileIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Profile sx={{ fill: 'none', ...sx }} viewBox="0 0 24 24" {...props} />
	);
}
