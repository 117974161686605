import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AxiosError } from 'axios';
import moment from 'moment';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactErrorBoundary } from '@/components/ReactErrorBoundary';
import { ToastContainer } from '@/components/ToastContainer';
import { HttpStatuses, MAX_RETRY_ATTEMPTS } from '@/constants/index';
import { AuthProvider } from '@/context/AuthContext';
import { KnowledgeBaseProvider } from '@/context/KnowledgeBase';
import { ColorSchemeProvider } from '@/context/Theme';
import { ToastProvider } from '@/context/Toast';
import { AppRouter } from '@/routes/index';

import 'react-toastify/dist/ReactToastify.css';
import 'react-data-grid/lib/styles.css';
import { useTelemetry } from './hooks/useTelemetry';

moment.updateLocale('en-gb', {
	week: {
		dow: 1,
	},
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error: unknown) => {
				const typedError = error as AxiosError;

				const queryIsNotAborted = !typedError?.config?.signal?.aborted;
				const errorIsNotAboutAuthorization =
					typedError?.response?.status !== HttpStatuses.Unauthorized;
				const errorIsNotAboutBadRequest =
					typedError?.response?.status !== HttpStatuses.BadRequest;
				const shouldRetry =
					queryIsNotAborted &&
					errorIsNotAboutAuthorization &&
					errorIsNotAboutBadRequest &&
					failureCount < MAX_RETRY_ATTEMPTS;

				return shouldRetry;
			},
		},
	},
});

export function App() {
	useTelemetry();

	return (
		<ReactErrorBoundary>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<QueryClientProvider client={queryClient}>
					<ColorSchemeProvider>
						<ToastProvider>
							<AuthProvider>
								<KnowledgeBaseProvider>
									<AppRouter />
									<ToastContainer position="top-right" />
								</KnowledgeBaseProvider>
							</AuthProvider>
						</ToastProvider>
					</ColorSchemeProvider>
				</QueryClientProvider>
			</LocalizationProvider>
		</ReactErrorBoundary>
	);
}
