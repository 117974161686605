type ScrollListener = (startRowIdx?: number, endRowIdx?: number) => void;

const scrollListeners: Set<ScrollListener> = new Set();

export const addScrollListener = (listener: ScrollListener) => {
	scrollListeners.add(listener);
};

export const removeScrollListener = (listener: ScrollListener) => {
	scrollListeners.delete(listener);
};

export const notifyScrollListeners = (
	startRowId?: number,
	endRowId?: number
) => {
	scrollListeners.forEach((listener) => {
		listener(startRowId, endRowId);
	});
};
