import { Chevron, DropTab, Popover } from '@mms/mms-ui-library';
import { RefObject, useRef, useState } from 'react';

import { useBookingContext } from '@/context/Booking';
import { useKnowledgeBase } from '@/context/index';
import { useOutsideClickMany } from '@/hooks/useOutsideClickMany';
import { OfficeKnowledgeType } from '@/types/KnowledgeBase';

import { bookingOfficeNames, officeSelectorOptions } from './constants';
import {
	OfficeName,
	OfficeSelectorChevronWrapper,
	OfficeSelectorWrapper,
} from './styled';
import { OfficeSelectorOption } from './types';

export function OfficeSelector() {
	const { knowledgeBase } = useKnowledgeBase();
	const { office, initialOffice, setOffice } = useBookingContext();
	const [isOpen, setIsOpen] = useState(false);

	const modalRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	const handleToggleIsOpen = () => {
		setIsOpen((prevOpen) => !prevOpen);
	};

	const handleClickOutside = () => {
		setIsOpen(false);
	};

	const changeOfficeLocation = (option: OfficeSelectorOption) => {
		const foundOffice = knowledgeBase?.Offices?.find(
			(off) => off.location === option.officeLocation
		);

		if (foundOffice) {
			setOffice(foundOffice);
		}
		setIsOpen(false);
	};

	useOutsideClickMany(
		[modalRef, containerRef as RefObject<HTMLElement>],
		handleClickOutside
	);

	const officeNameToRender = bookingOfficeNames[office.location];

	const value: OfficeKnowledgeType = office ?? initialOffice;

	const valueForDroptab: OfficeSelectorOption = {
		name: bookingOfficeNames[value.location],
		officeLocation: value.location,
	};

	return (
		<OfficeSelectorWrapper ref={containerRef} onClick={handleToggleIsOpen}>
			<OfficeName variant="small">{officeNameToRender}</OfficeName>
			<OfficeSelectorChevronWrapper isOpen={isOpen}>
				<Chevron />
			</OfficeSelectorChevronWrapper>
			<Popover
				withTransition={false}
				ref={modalRef}
				anchorElement={containerRef.current}
				distanceToAnchorElement={9}
				open={isOpen}
				position="bottom-end"
			>
				<DropTab
					options={officeSelectorOptions}
					value={valueForDroptab}
					onChange={changeOfficeLocation}
					valueField="name"
					gapBetweenItems={0}
					width="200px"
				/>
			</Popover>
		</OfficeSelectorWrapper>
	);
}
