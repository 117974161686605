import axios from 'axios';
import { useQuery } from 'react-query';

import {
	GET_NOTIFICATIONS,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
	NotificationType,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';

import type { GetNotificationsResponse } from './types';

export const useGetNotifications = () => {
	const toast = useToast();

	return useQuery({
		queryKey: BookingQueriesKeys.notifications,
		queryFn: async () => {
			const { data } = await axios.get<GetNotificationsResponse>(
				GET_NOTIFICATIONS
			);

			return data;
		},
		refetchOnWindowFocus: false,
		onError() {
			toast.open(
				createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
			);
		},
	});
};
