import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import {
	BOOKING_MOBILE_SCREEN_WIDTH,
	BOOKING_TABS_CHANGE_SCREEN_WIDTH,
	BOOKING_SMALL_MOBILE_SCREEN_WIDTH,
} from '../../constants';

export const BookingsBlock = styled('div')(({ theme }) => ({
	border: `1px solid ${theme.palette['primary-4']}`,
	backgroundColor: theme.palette['primary-1'],
	borderRadius: 6,
	height: '100%',
	overflow: 'hidden',
	minWidth: 666,
	maxWidth: 750,

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		minWidth: 100,
		border: 'none',
		backgroundColor: 'transparent',
		borderRadius: 0,
	},
}));

export const BookingsHeaderWrapper = styled('div')(({ theme }) => ({
	borderTopLeftRadius: 6,
	borderTopRightRadius: 6,
	background: theme.palette['exc-primaryHeader-primary-10'],
	borderBottom: `1px solid ${theme.palette['primary-4']}`,
	color: theme.palette.secondary.contrastText,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: 40,
	flexDirection: 'row',
	padding: '0px 20px',

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		background: 'transparent',
		border: 'none',
		height: 'auto',
		padding: '0px 0px',
	},
}));

export const BookingsHeaderRightMenuWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	gap: theme.spaces.m,
	'& > button': {
		padding: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export const BookingsTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette['exc-primaryHeader-primary-1'],

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		color: theme.palette['base-6'],
	},

	[theme.breakpoints.down('sm')]: {
		fontSize: theme.typography.pxToRem(14),
	},
}));

export const BookingsBody = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '1fr 284px',
	height: '100%',

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		gridTemplateColumns: '1fr',
		gridTemplateReows: '1fr 1fr',
		gap: theme.spaces.xl2,
		height: 'auto',
	},
}));

export const BookingBlockWrapper = styled('div')(({ theme }) => ({
	maxWidth: 750,

	overflow: 'hidden',

	[theme.breakpoints.down(BOOKING_TABS_CHANGE_SCREEN_WIDTH)]: {
		justifySelf: 'center',
	},

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		justifySelf: 'stretch',
		padding: '0px 4px 190px 4px',

		overflow: 'unset',
	},

	[theme.breakpoints.down(BOOKING_SMALL_MOBILE_SCREEN_WIDTH)]: {
		padding: '0px 4px 0px 4px',
	},
}));
