import moment, { Moment } from 'moment';

export const getRepetitiveWeekDays = (
	momentDateList: Moment[],
	weeksInInterval: number
) => {
	const dateFrom = momentDateList[0];
	const dateTo = momentDateList[1];

	const repetitiveWeekDays: Moment[] = [];

	const middleDate = moment(dateFrom);

	while (middleDate.isSameOrBefore(dateTo)) {
		repetitiveWeekDays.push(middleDate.clone());
		middleDate.add(weeksInInterval, 'week');
	}

	return repetitiveWeekDays;
};

export const getDateRange = (dateFrom: Moment, dateTo: Moment): Moment[] => {
	const dates: Moment[] = [];

	const currentDate = dateFrom.clone();

	while (currentDate.isSameOrBefore(dateTo, 'day')) {
		dates.push(currentDate.clone());
		currentDate.add(1, 'day');
	}

	return dates;
};
