import { BookingIcon, DeskIcon } from '@mms/mms-ui-library';

import { Employee } from '@/context/types';
import { LocationType, OfficeKnowledgeType } from '@/types/KnowledgeBase';

export const COLOR_MAP = {
	available: 'base-2',
	blocked: 'feedback-info',
	yourBlocked: 'accent-6',
	booked: 'feedback-success',
	selected: 'accent-6',
};

export const DEFAULT_BOOKING_OFFICE: OfficeKnowledgeType = {
	id: '1',
	name: 'Minsk',
	description: null,
	location: LocationType.Minsk,
};

export const DEFAULT_EMPLOYEE: Employee = {
	id: 0,
	name: 'Modsen Company',
	positionDescription: 'Employee',
};

export const bookingTabs = [
	{
		id: 0,
		text: 'Bookings',
		icon: <BookingIcon />,
	},
	{
		id: 1,
		text: 'Desks',
		icon: <DeskIcon />,
	},
];

export const bookingInitialTabIndex = bookingTabs[0].id;
export const BOOKING_BLOCK_HEADER_HEIGHT = 40;
export const BOOKING_TAB_INDEX_STORAGE_KEY = 'booking-tab-index';
export const BOOKING_DESKTOP_CHANGE_SCREEN_WIDTH = 2560;
export const BOOKING_LAPTOP_CHANGE_SCREEN_WIDTH = 1920;
export const BOOKING_TABS_CHANGE_SCREEN_WIDTH = 1420;
export const BOOKING_MOBILE_SCREEN_WIDTH = 780;
export const BOOKING_SMALL_MOBILE_SCREEN_WIDTH = 450;
export const BOOKING_DESK_SEAT_WIDTH = 10;

export const WORKPLACE_ELEMENT_ID = 'workplace';
