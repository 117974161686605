import { Tooltip } from '@mms/mms-ui-library';
import { useCallback, memo } from 'react';

import { getPortalContainer } from '@/components/Grid/utils/getPortalContainer';
import { BookPlaceType } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { getFullName } from '@/helpers/utils';
import { useScreenSizeDown } from '@/hooks/index';
import {
	BOOKING_MOBILE_SCREEN_WIDTH,
	WORKPLACE_ELEMENT_ID,
} from '@/pages/Booking/constants';

import {
	StyledDesk,
	DeskTitle,
	BookedAvatar,
	DeskTooltipTextWrapper,
} from './styled';
import type { DeskInnerProps } from './types';

const { Blocked, Booked } = BookPlaceType;

function DeskInner({
	firstName,
	lastName,
	userId,
	deskTitle,
	workplaceNumber,
	isBlocked,
	isSelected,
	onClick,
	rotationDegree,
}: DeskInnerProps) {
	const { id: personId } = useAuth();
	const userInitials =
		lastName && firstName && getFullName(firstName, lastName);

	const isBooked = !isBlocked && !!userId && userId !== personId;
	const isBookedByUser = userId === personId;

	const isMobile = useScreenSizeDown(BOOKING_MOBILE_SCREEN_WIDTH);

	const handleClick = useCallback(
		() => onClick(isSelected ? 0 : workplaceNumber),
		[onClick, isSelected]
	);

	return (
		<Tooltip
			open={Boolean(userInitials)}
			position="right"
			header={
				<DeskTooltipTextWrapper isMobile={isMobile}>
					Desk is {isBlocked ? Blocked : Booked} by {userInitials}
				</DeskTooltipTextWrapper>
			}
			getPortalContainer={getPortalContainer(`#${WORKPLACE_ELEMENT_ID}`)}
			fallbackPlacements={[
				'bottom',
				'right',
				'left',
				'top',
				'top-start',
				'top-end',
				'bottom-start',
				'bottom-end',
				'left-start',
				'left-end',
				'right-start',
				'right-end',
			]}
		>
			<StyledDesk
				rotationDegree={rotationDegree}
				isBooked={isBooked}
				isBlocked={isBlocked}
				isBookedByUser={isBookedByUser}
				onClick={handleClick}
				number={workplaceNumber}
				selected={isSelected}
				className={`desk-${workplaceNumber}`}
			>
				<DeskTitle Component="h6" variant="xs-500">
					<>
						{deskTitle}
						{userInitials && (
							<BookedAvatar
								fileId=""
								firstName={firstName}
								lastName={lastName}
							/>
						)}
					</>
				</DeskTitle>
			</StyledDesk>
		</Tooltip>
	);
}

export const Desk = memo(DeskInner);
