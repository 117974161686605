import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_BOOKING_API_ENDPOINT,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { useToast } from '@/context/index';
import { createToast } from '@/helpers/createToast';

import { GetBookingResponse } from './types';

interface UseGetBookingsOptions {
	officeId: string;
	userId: number;
	includeEmployee: boolean;
}

export const useGetBookings = ({
	officeId,
	userId,
	includeEmployee,
}: UseGetBookingsOptions) => {
	const toast = useToast();

	return useQuery({
		queryKey: [BookingQueriesKeys.bookings, userId, includeEmployee, officeId],
		queryFn: async () => {
			try {
				const response = axios.get<GetBookingResponse>(
					GET_BOOKING_API_ENDPOINT,
					{
						params: {
							userId,
							includeEmployee,
							officeId,
						},
					}
				);

				return (await response).data;
			} catch (error) {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		keepPreviousData: true,
		enabled: Boolean(userId),
		refetchOnWindowFocus: false,
	});
};
