import { Tooltip } from '@mms/mms-ui-library';
import { memo } from 'react';

import { Square } from '@/components/Square';
import { COLORS, COLORS_ENUM } from '@/constants/index';
import { useScreenSizeDown } from '@/hooks/index';
import { BOOKING_MOBILE_SCREEN_WIDTH } from '@/pages/Booking/constants';

import {
	SquareWrapper,
	LegendWrapper,
	LegendTitle,
	ColorTooltipTextWrapper,
} from './styled';

function LegendInner() {
	const showTooltipInLegend = useScreenSizeDown(BOOKING_MOBILE_SCREEN_WIDTH);

	return (
		<LegendWrapper>
			{COLORS.map((color) => (
				<SquareWrapper key={color}>
					{showTooltipInLegend ? (
						<Tooltip
							text={
								<ColorTooltipTextWrapper>
									{color === COLORS_ENUM.Selected ? 'Your desk' : color}
								</ColorTooltipTextWrapper>
							}
						>
							<Square type={color} side={24} />
						</Tooltip>
					) : (
						<>
							<Square type={color} side={24} />
							<LegendTitle Component="p" variant="m-400">
								{color === COLORS_ENUM.Selected ? 'Your desk' : color}
							</LegendTitle>
						</>
					)}
				</SquareWrapper>
			))}
		</LegendWrapper>
	);
}

export const Legend = memo(LegendInner);
