import { useMemo } from 'react';

import { useResize } from '.';

export const useScreenSizeDown = (width: number) => {
	const [innerWidth] = useResize();

	const isDownThanProvidedWidth = useMemo(
		() => innerWidth <= width,
		[innerWidth, width]
	);

	return isDownThanProvidedWidth;
};
