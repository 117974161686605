import { CrossIcon } from '@mms/mms-ui-library';
import { IconButton, useTheme } from '@mui/material';
import moment from 'moment';
import { memo } from 'react';

import { useWorkplaceConfiguration } from '../../BookingWorkplace/hooks/useWorkplaceConfiguration';

import { isNotChiefRole } from '@/constants/roles';
import {
	getBookingPlaceType,
	getFullName,
	createPeriodInText,
	getBookType,
} from '@/helpers/index';

import {
	ListItemContent,
	StyledListItem,
	IconCloseWrapper,
	StyledTypography,
	Divider,
} from './styled';
import type { ItemInnerProps } from './types';

function ItemInner({
	currentUserId,
	userRole,
	userId,
	isBlock,
	lastName,
	firstName,
	weeksInInterval,
	dateList,
	workplaceNumber,
	handleDelete,
	office,
}: ItemInnerProps) {
	const { deskTitleAbriviation } = useWorkplaceConfiguration({ office });

	const theme = useTheme();

	return (
		<>
			<StyledListItem
				type={getBookingPlaceType(currentUserId, userId, isBlock)}
			>
				<ListItemContent>
					{userId !== currentUserId && lastName && firstName && (
						<StyledTypography
							Component="p"
							variant="s-600"
							color={theme.palette['base-6']}
						>
							{getFullName(firstName, lastName)}
						</StyledTypography>
					)}
					{isBlock ? (
						<StyledTypography
							Component="p"
							variant="s-600"
							color={theme.palette['base-6']}
						>
							From {moment(dateList[0]).format('DD MMMM, YYYY')}
						</StyledTypography>
					) : (
						createPeriodInText(weeksInInterval, dateList)
							?.split('\n')
							.map((line, index) => (
								<StyledTypography
									key={index}
									Component="p"
									variant="s-600"
									color={theme.palette['base-6']}
								>
									{line}
								</StyledTypography>
							))
					)}

					<StyledTypography
						Component="p"
						variant="m-400"
						color={theme.palette['base-6']}
					>
						{`${deskTitleAbriviation}-${workplaceNumber} - ${getBookType(
							weeksInInterval,
							dateList
						)}`}
					</StyledTypography>
				</ListItemContent>
				<IconCloseWrapper>
					{!(isBlock && isNotChiefRole(userRole)) && (
						<IconButton
							size="small"
							onClick={handleDelete}
							sx={{ padding: 0, color: theme.palette['accent-6'] }}
						>
							<CrossIcon />
						</IconButton>
					)}
				</IconCloseWrapper>
			</StyledListItem>
			<Divider />
		</>
	);
}

const areItemPropsEqual = (
	prevProps: { userId: number },
	nextProps: { userId: number }
) => prevProps.userId === nextProps.userId;

export const Item = memo(ItemInner, areItemPropsEqual);
