import { useEffect } from 'react';

import type { UsePrependInitialValueProps } from './types';

export const usePrependInitialValue = ({
	options,
	initialValue,
	dependency,
}: UsePrependInitialValueProps) => {
	useEffect(() => {
		if (options && options.length > 0 && initialValue) {
			options.unshift(initialValue);
		}
	}, [dependency, options, initialValue]);
};
