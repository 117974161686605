import { Typography } from '@mms/mms-ui-library';
import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
	BOOKING_MOBILE_SCREEN_WIDTH,
	COLOR_MAP,
} from '@/pages/Booking/constants';

import { StyledListItemProps } from './types';

export const StyledListItem = styled('div')<StyledListItemProps>(
	({ theme, type }) => ({
		position: 'relative',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between',
		padding: `${theme.spaces.s}px`,
		paddingLeft: `${theme.spaces.m}px`,
		transition: 'all 0.2s ease-in-out',
		'&::before': {
			content: '" "',
			position: 'absolute',
			height: '100%',
			top: 0,
			left: 0,
			backgroundColor:
				theme.palette[
					COLOR_MAP[
						type as keyof typeof COLOR_MAP
					] as keyof typeof theme.palette
				],
			width: 6,
		},
		'&:hover': {
			backgroundColor: alpha(theme.palette.timeSheets.mainHover, 0.08),
		},
	})
);

export const ListItemContent = styled('div')(() => ({
	width: 226,
}));

export const StyledTypography = styled(Typography)({
	display: 'inlineBlock',
	overflow: 'hidden',
	width: 226,
	textWrap: 'wrap',
	overflowWrap: 'anywhere',
	whiteSpace: 'pre-wrap',
});

export const IconCloseWrapper = styled('div')(() => ({
	display: 'flex',
	maxWidth: 56,
	justifyContent: 'flex-start',
	alignItems: 'center',
}));

export const Divider = styled('div')(({ theme }) => ({
	width: '100%',
	height: 1,
	background: theme.palette['primary-4'],

	'&:last-child': {
		display: 'none',
	},

	[theme.breakpoints.down(BOOKING_MOBILE_SCREEN_WIDTH)]: {
		background: 'transparent',
	},
}));
