import { TimesheetModeFilterOption } from './types';

export const WEEK_MINIMAL_TRACK_CELL_WIDTH = 122;
export const MONTH_MINIMAL_TRACK_CELL_WIDTH = 58;
export const MOBILE_MINIMAL_TRACK_CELL_WIDTH = 62;

export const TIMESHEET_USER_NAME_COLUMN_WIDTH = 443;
export const TIMESHEET_USER_NAME_COLUMN_WIDTH_MOBILE = 256;
export const TIMESHEET_TOTAL_COLUMN_WIDTH = 90;
const visibleWeekColumnsCount = 3;

export const ADD_TASK_CELL_CLASS_NAME = 'add-task-cell';
export const TASK_INFO_CELL_CLASS_NAME = 'engineer-task-info-cell';

export const TIMESHEET_FREEZE_COLUMNS_BREAKPOINT = Math.round(
	TIMESHEET_USER_NAME_COLUMN_WIDTH +
		TIMESHEET_TOTAL_COLUMN_WIDTH +
		visibleWeekColumnsCount * WEEK_MINIMAL_TRACK_CELL_WIDTH
);

export enum TimeSheetBreakpoints {
	DESKTOP = 1255,
	TABLET_LG = 860,
	TABLET = 600,
	MOBILE = 400,
}

export const USER_INFO_COLUMN_HOVER_INDEX = 1;
export const getTotalColumnHoverIndex = (periodLenght: number) =>
	periodLenght + 2;
export const CLOSE_DELAY_ROLE_FILTER_MS = 200;

export const timesheetModesIds = {
	departmentModeId: 'Department mode',
	projectsModeId: 'Projects mode',
	personalModeId: 'Personal mode',
} as const;

const { projectsModeId, personalModeId, departmentModeId } = timesheetModesIds;

export const defaultInitialTimesheetMode: TimesheetModeFilterOption = {
	id: departmentModeId,
	description: departmentModeId,
};

export const defaultTimesheetModes: Array<TimesheetModeFilterOption> = [
	defaultInitialTimesheetMode,
	{ id: personalModeId, description: personalModeId },
];

export const timesheetModesForHeadProjectManager: Array<TimesheetModeFilterOption> =
	[
		defaultInitialTimesheetMode,
		{ id: projectsModeId, description: projectsModeId },
		{ id: personalModeId, description: personalModeId },
	];

export const timesheetModesForProjectManager: Array<TimesheetModeFilterOption> =
	[
		{ id: projectsModeId, description: projectsModeId },
		{ id: personalModeId, description: personalModeId },
	];

export const initialPMOEmployeeTimesheetMode =
	timesheetModesForProjectManager[0];
