import { Typography } from '@mms/mms-ui-library';
import { Box, MenuItem, styled } from '@mui/material';

import { Avatar } from '@/components/Avatar';

const avatarSize = 90;

export const DropdownWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	width: 266,
});

export const DropdownHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: theme.spacing(1),
	height: 54,
}));

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
	fontWeight: 600,
	fontSize: theme.typography.pxToRem(24),
	lineHeight: theme.typography.pxToRem(36),
	marginTop: theme.spacing(3),
	width: avatarSize,
	height: avatarSize,
	'& img': {
		width: avatarSize,
		height: avatarSize,
	},
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 3, 1),
}));

export const DropdownFooter = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	margin: theme.spacing(2, 0, 0.5, 0),
}));

export const Email = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: (theme.palette as any)['base-5'],
}));

export const TimeZoneTypography = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

export const Position = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(1),
	color: (theme.palette as any)['accent-6'],
}));

export const Surname = styled(Typography)(({ theme }) => ({
	marginTop: theme.spacing(7),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	color: (theme.palette as any)['base-6'],
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	height: 54,
	background: theme.palette['exc-primaryHeader-primary-10'],
	padding: theme.spacing(2),
}));

export const AvatarWrapper = styled(Box)({
	height: 40,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
	padding: theme.spacing(1.3, 0),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	lineHeight: theme.typography.pxToRem(19),
	color: theme.palette['base-6'],
}));
